<template>
  <div class="manage">
    <filter-bar
      :itemArray="filterBarItemArray"
      :initialSelectedIndex="selectedComponentIndex"
      :buttonTitle="buttonTitle"
      :keywordTitle="keywordTitle"
      :keyword="keyword"
      @onItemSelected="onItemSelected"
      @onButtonClick="onButtonClick"
      @onSearchClick="onKeywordSearch"
    />
    <component
      ref="list"
      :is="currentComponent"
      :keyword="keyword"
      @onCheckMemberClick="onCheckMemberClick"
      @onEditClick="onEditClick"
      @onEditionCellCheck="onEditionCellCheck"
    ></component>
    <!-- OrganizationAlert -->
    <manage-alert-organization
      v-if="isShowOrganizationAlert"
      v-model="isShowOrganizationAlert"
      :hospitalModel="selectedHospitalModel"
      @onSucceed="onOrganizationSucceed"
    ></manage-alert-organization>
    <!-- MemberAlert -->
    <manage-alert-member
      v-if="isShowMemberAlert"
      v-model="isShowMemberAlert"
      :memberModel="selectedMemberModel"
      @onSucceed="onMemberSucceed"
    ></manage-alert-member>
    <el-drawer
      :visible.sync="isShowDrawer"
      :with-header="false"
      size="680"
      destroy-on-close
    >
      <manage-member-panel
        ref="panel"
        v-model="isShowDrawer"
        :hospitalModel="selectedHospitalModel"
        @onEdit="onMemberPanelEdit"
        @onAdd="onMemberPanelAdd"
      ></manage-member-panel>
    </el-drawer>
    <!-- EditionAlert -->
    <manage-alert-edition-detail
      v-if="isShowEditionAlertDetail"
      v-model="isShowEditionAlertDetail"
      :model="selectedEditionModel"
    ></manage-alert-edition-detail>
    <manage-alert-edition-add
      v-if="isShowEditionAlertAdd"
      v-model="isShowEditionAlertAdd"
      :model="selectedEditionModel"
      @onSucceed="onEditionAddSucceed"
    ></manage-alert-edition-add>
  </div>
</template>

<script>
import FilterBar from "@c/operation-log/common/filter-bar/filter-bar.vue";
import Organization from "@c/manage/organization/organization.vue";
import Member from "@c/manage/member/member.vue";
import Edition from "@c/manage/edition/edition.vue";
import ManageAlertOrganization from "@c/manage/manage-alert/manage-alert-organization.vue";
import ManageAlertMember from "@c/manage/manage-alert/manage-alert-member/manage-alert-member.vue";
import ManageMemberPanel from "@c/manage/manage-member-panel.vue";
import ManageAlertEditionDetail from "@c/manage/manage-alert/manage-alert-edition-detail.vue";
import ManageAlertEditionAdd from "@c/manage/manage-alert/manage-alert-edition-add.vue";

export default {
  components: {
    FilterBar,
    Organization,
    Member,
    Edition,
    ManageAlertOrganization,
    ManageAlertMember,
    ManageMemberPanel,
    ManageAlertEditionDetail,
    ManageAlertEditionAdd,
  },

  data() {
    return {
      selectedComponentIndex: undefined,
      duration: undefined,
      buttonTitle: undefined,
      keywordTitle: undefined,
      keyword: "",
      selectedHospitalModel: undefined,
      selectedMemberModel: undefined,
      selectedEditionModel: undefined,
      isShowOrganizationAlert: false,
      isShowMemberAlert: false,
      isShowDrawer: false,
      isShowEditionAlertDetail: false,
      isShowEditionAlertAdd: false,
    };
  },

  computed: {
    currentComponent() {
      switch (this.selectedComponentIndex) {
        case 0:
          if (this.$store.getters.isSeniorAdministrator) {
            return "organization";
          }
          if (this.$store.getters.isSalesManager) {
            return "edition";
          }
        case 1:
          return "member";
        default:
          return "edition";
      }
    },

    filterBarItemArray() {
      if (this.$store.getters.isSeniorAdministrator) {
        return ["机构列表", "用户列表", "应用版本列表"];
      }
      if (this.$store.getters.isSalesManager) {
        return ["应用版本列表"];
      }
    },
  },

  mounted() {
    const moduleName = this.filterBarItemArray[0];
    this.selectedComponentIndex = 0;
    switch (moduleName) {
      case "机构列表":
        this.buttonTitle = "新增机构";
        this.keywordTitle = "机构名称";
        break;
      case "用户列表":
        this.buttonTitle = "新增用户";
        this.keywordTitle = "姓名/手机号/所属机构";
        break;
      case "应用版本列表":
        this.buttonTitle = this.$store.getters.isSeniorAdministrator
          ? "新增版本"
          : undefined;
        this.keywordTitle = undefined;
        break;
      default:
        break;
    }
  },

  watch: {
    currentComponent(newValue) {
      switch (newValue) {
        case "organization":
          this.buttonTitle = "新增机构";
          this.keywordTitle = "机构名称";
          break;
        case "member":
          this.buttonTitle = "新增用户";
          this.keywordTitle = "姓名/手机号/所属机构";
          break;
        default:
          this.buttonTitle = this.$store.getters.isSeniorAdministrator
            ? "新增版本"
            : undefined;
          this.keywordTitle = undefined;
      }
    },
  },

  methods: {
    //filter-bar
    onItemSelected(index) {
      this.selectedComponentIndex = index;
      this.keyword = "";
    },

    onButtonClick() {
      switch (this.buttonTitle) {
        case "新增机构":
          this.selectedHospitalModel = undefined;
          this.isShowOrganizationAlert = true;
          break;
        case "新增用户":
          this.selectedMemberModel = undefined;
          this.isShowMemberAlert = true;
          break;
        case "新增版本":
          this.selectedEditionModel = undefined;
          this.isShowEditionAlertAdd = true;
          break;
        default:
          break;
      }
    },

    onKeywordSearch(keyword) {
      this.keyword = keyword;
    },

    //member-panel
    onMemberPanelEdit(memberModel) {
      this.selectedMemberModel = memberModel;
      this.isShowMemberAlert = true;
    },

    onMemberPanelAdd() {
      this.selectedMemberModel = undefined;
      this.isShowMemberAlert = true;
    },

    //cell
    onCheckMemberClick(hospitalModel) {
      this.isShowDrawer = true;
      this.selectedHospitalModel = hospitalModel;
    },

    onEditClick(model) {
      switch (this.buttonTitle) {
        case "新增机构":
          this.selectedHospitalModel = model;
          this.isShowOrganizationAlert = true;
          break;
        case "新增用户":
          this.selectedMemberModel = model;
          this.isShowMemberAlert = true;
          break;
        default:
          break;
      }
    },

    onEditionCellCheck(model) {
      this.selectedEditionModel = model;
      this.isShowEditionAlertDetail = true;
    },

    onOrganizationSucceed(isEdit) {
      this.isShowOrganizationAlert = false;
      const organizationView = this.$refs.list;
      if (isEdit) {
        organizationView.getOrganizationList();
      } else {
        this.keyword = ""; //实践证明不会触发属性观察器
        organizationView.currentPage = 1;
        organizationView.getOrganizationList();
      }
    },

    onMemberSucceed(isEdit) {
      this.isShowMemberAlert = false;
      const memberView = this.$refs.list;
      if (isEdit) {
        memberView.getMemberList();
      } else {
        this.keyword = ""; //实践证明不会触发属性观察器
        memberView.currentPage = 1;
        memberView.getMemberList();
      }
    },

    onEditionAddSucceed() {
      this.isShowEditionAlertAdd = false;
      const editionView = this.$refs.list;
      this.keyword = ""; //实践证明不会触发属性观察器
      editionView.getEditionList();
    },
  },
};
</script>

<style lang="scss" scoped>
.manage {
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  padding: rem(0) rem(33) rem(0) rem(36);
}
</style>
