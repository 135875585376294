import Vue from "vue";
import { post, get, upload, httpNew } from '@js/http-new.js';

class API {
  //登录
  sendVerifyCode = (params) => post("base/sms/seed", params)
  passwordLogin = (params) => post("web/user/auth/login", params)
  verifyCodeLogin = (params) => post("web/user/auth/loginPhone", params)
  getUserInfo = (params) => get("web/user/load", params)
  logout = (params) => get("web/user/logout", params)
  //医院列表
  getHospitalList = (params) => post("web/hospital/list", params)
  //首页统计
  getCaculate = (params) => get("web/hospital/statistics", params)
  //监测中心
  getMoniterCenterData = (params) => post("web/device/monitoringCenterList", params)
  getMonitorCenterDeviceDetail = (params) => get("web/device/monitoringDeviceDetail", params)
  //设备借还
  deviceRecycle = (params) => post("web/device/recovery", params)
  deviceBorrow = (params) => post("web/device/lend", params)
  getDeviceList = (params) => post("web/device/pageList", params)
  getDeviceInfo = (params) => get("web/device/load", params)
  deleteDevice = (params) => get("web/device/del", params)
  editDevice = (params) => post("web/device/update", params)
  stopDevice = (params) => get("web/device/stop", params)
  addDevice = (params) => post("web/device/add", params)
  //用户
  getUserList = (params) => post("web/patientUser/list", params)
  getUserPageList = (params) => post("web/patientUser/pageList", params)
  getUserDetail = (params) => get("web/patientUser/load", params)
  deleteUser = (params) => get("web/patientUser/del", params)
  createUser = (params) => post("web/patientUser/add", params)
  editUser = (params) => post("web/patientUser/update", params)
  //睡眠报告
  getSleepReportList = (params) => post("v2/web/sleepReport/list", params)
  getSleepReportPageList = (params) => post("v2/web/sleepReport/pageList", params)
  getSleepReportDetail = (params) => post("v2/web/sleepReport/detail", params)
  editReportDetail = (params) => post("v2/web/sleepReport/update", params)
  exportReport = (params) => post("v2/web/sleepReport/export", params) //新加入还没接
  deleteRequest = (params) => get("v2/web/sleepReport/del", params)
  //日志
  getLogList = (params) => post("web/log/pageList", params)
  getMonitorLogList = (params) => post("web/log/monitoringList", params)
  getDeviceWarningList = (params) => post("web/log/sleepWarningLog", params)
  getPadLogList = (params) => post("web/log/handleLog", params)

  //设置
  getLeaderInfo = (params) => get("web/user/getLeader", params)
  smsVerification = (params) => post("web/hospital/setup/smsVerification", params)
  getOrganizationConfig = (params) => get("web/hospital/setup/loadOrgSetup", params)
  editOrganizationConfig = (params) => post("web/hospital/setup/orgSetup", params)
  getSleepReportConfig = (params) => get("web/hospital/setup/loadReportSetup", params)
  editSleepReportConfig = (params) => post("web/hospital/setup/reportSetup", params)
  getBloodOxygenReportConfig = (params) => get("web/hospital/setup/loadAppReportSetup", params)
  editBloodOxygenReportConfig = (params) => post("web/hospital/setup/appReportSetup", params)
  getDevelopConfig = (params) => get("web/hospital/setup/loadDevelopmentSetup", params)
  editDevelopConfig = (params) => post("web/hospital/setup/developmentSetup", params)
  accessFresh = (params) => get("web/hospital/setup/accessFresh", params)
  //管理
  getOrganizationList = (params) => post("web/hospital/pageList", params)
  addHospital = (params) => post("web/hospital/addOrUpdate", params)
  getHospitalDetail = (params) => get("web/hospital/load", params)
  getMenberList = (params) => post("web/user/pageList", params)
  addMember = (params) => post("web/user/addOrUpdate", params)
  getMemberDetail = (params) => get("web/user/load", params)
  deleteHospital = (params) => get("web/hospital/del", params)
  getEditonList = (params) => post("web/software/list", params)
  addNewEdition = (params) => post("web/software/save", params)

  //设备管理
  deleteRing = (params) => get("web/sleepMonitorRing/del", params)
  addRing = (params) => post("web/sleepMonitorRing/save", params)
  getRingList = (params) => post("web/sleepMonitorRing/pageList", params)
  getChildRingList = (params) => post("web/oximetry/ring/pageList", params)

  //睡眠初筛报告
  getBloodOxygenReportList = (params) => post("v2/flat/ring/xyRingReport/pageList", params)
  getBloodOxygenReportDetail = (params) => post("v2/flat/ring/xyRingReport/load", params)
  getBloodOxygenReportDownloadURL = (params) => post("v2/flat/ring/xyRingReport/downloadReport", params)
  saveBloodOxygenReportSuggestion = (params) => post("v2/flat/ring/xyRingReport/updateSuggest", params)
  saveBloodOxygenReportUserInfo = (params) => post("v2/flat/ring/xyRingReport/updateUserInfo", params)
  exportBloodOxygenReport = (params) => httpNew.post("v2/flat/ring/xyRingReport/export", params, { responseType: 'blob' })

  //统计分析
  getstatisticalAnalysis = (params) => post("v2/flat/sleepAssessment/statisticalAnalysis", params)
  //下载导出结果
  downloadAssessment = (params) => post("v2/flat/sleepAssessment/downloadAssessment", params)
  //问卷-终止任务
  endMission = (params) => post("v2/flat/sleepAssessmentTask/end", params)
  //问卷-删除任务
  deleteMission = (params) => get("v2/flat/sleepAssessmentTask/del", params)
  //生成pdf文件
  generatePdf = (params) => post("v2/base/pdf/generate", params)
  //修改密码
  changePassword = (params) => post("web/user/changePassword", params)
  //问卷筛查列表
  assessmentTaskList = (params) => post("v2/flat/sleepAssessmentTask/pageList", params)
  //获取筛查任务详情
  assessmentTaskInfo = (params) => post("v2/flat/sleepAssessmentTask/assessmentTaskInfo", params)
  //问卷-结果列表
  assessmentResultList = (params) => post("v2/flat/sleepSelfAssessment/pageList", params)
  //问卷-结果详情
  getAssessmentDetail = (params) => get("v2/flat/sleepSelfAssessment/selfLoad", params)
  //问卷-问卷列表
  assessemntList = (params) => post("v2/flat/sleepAssessment/list", params)
  //问卷-添加任务
  addTask = (params) => post("v2/flat/sleepAssessmentTask/saveOrUpdate", params)
  //问卷-在线生成PDF和PNG
  generatePDFAndPNG = (params) => get("v2/flat/sleepSelfAssessment/assessmentGenerateUrl", params)
  //获取当前用户登录信息
  userInfo = (params) => get("web/user/loadLoginInfo", params)
  //日志-日志列表
  logList = (params) => post("web/log/logList", params)
  //日志-添加日志
  saveLog = (params) => post("web/log/saveLog", params)
  //报告生成pdf和图片url
  reportGenerateUrl = (params) => get("v2/web/sleepReport/reportGenerateUrl", params)
  //获取机构推送配置
  loadOrgSendSetup = (params) => get("web/hospital/setup/loadOrgSendSetup", params)
  //修改机构推送配置
  orgSendSetup = (params) => post("web/hospital/setup/orgSendSetup", params)

  //血氧设备日志
  bloodOxygenDeviceLog = (params) => post("base/xyDeviceLog/pageList", params)

  //上传文件并指定文件名
  uplodFileWithNameRequest = (formData, onProgress = null) => { return upload("base/aly/oss/uploadFileFileName", formData, onProgress) }
}

Vue.prototype.$api = new API()

