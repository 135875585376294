<template>
  <div class="system">
    <page-list-container
      :total="total"
      :pageSize="pageSize"
      :inputCurrentPage="currentPage"
      @onPageChange="onPageChange"
      @onSizeChange="onSizeChange"
    >
      <div>
        <ring-title-bar :canDeleteDevice="canDeleteDevice"></ring-title-bar>
        <ring-cell
          v-for="(item, index) of dataArray"
          :key="index"
          :model="item"
          :canDeleteDevice="canDeleteDevice"
          :index="index"
          :isSelected="selectedIndex === index"
          @onSelectClick="onCellSelectClick"
          @onDeleteRingSucceed="onDeleteRingSucceed"
        ></ring-cell>
      </div>
    </page-list-container>
    <ring-add
      v-if="isShowAddRing"
      @onCloseClick="onAddRingCloseClick"
      @onConfirmClick="onAddRingConfirmClick"
    ></ring-add>
  </div>
</template>

<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import RingTitleBar from "@c/device-manage/ring/ring-title-bar.vue";
import RingCell from "@c/device-manage/ring/ring-cell.vue";
import RingAdd from "@c/device-manage/ring/ring-add.vue";
export default {
  name: "organization",
  components: {
    PageListContainer,
    RingTitleBar,
    RingCell,
    RingAdd,
  },

  props: {
    canDeleteDevice: Boolean,
  },

  data() {
    return {
      //列表
      dataArray: [],
      total: 0,
      pageSize: 5,
      currentPage: 1,
      selectedModel: null,
      selectedIndex: null,
      pageType: "manage",
      keyword: undefined,
      //add-ring
      isShowAddRing: false,
    };
  },

  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },
  },

  mounted() {
    this.pageSize = bestPageSizeComputer();
    this.getRingList();
  },

  watch: {
    hospitalID() {
      this.currentPage = 1;
      this.getRingList();
    },
  },

  methods: {
    //cell
    onCellSelectClick(index) {
      this.selectedIndex = index;
      this.selectedModel = this.dataArray[index];
    },

    onSearchClick(value) {
      this.currentPage = 1;
      this.keyword = value;
      this.getRingList();
    },

    onDeleteRingSucceed() {
      this.getRingList();
    },

    onAddDeviceClick() {
      if (this.hospitalID == null) {
        this.$toast.showRed(MSG.pickHospitalRemind);
        return;
      }
      this.isShowAddRing = true;
    },

    // add-ring
    onAddRingCloseClick() {
      this.isShowAddRing = false;
    },

    onAddRingConfirmClick(info) {
      this.ringAddRequest(info);
    },

    //分页
    onPageChange(currentPage) {
      this.currentPage = currentPage;
      this.getRingList();
    },

    onSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.getRingList();
    },

    // method
    async getRingList() {
      this.selectedIndex = null;
      this.selectedModel = null;
      try {
        const params = {
          hospitalId: this.hospitalID,
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          ringSn: this.keyword,
        };
        const data = await this.$api.getRingList(params);
        this.dataArray = data.list;
        this.total = data.total;
        this.totalPage = data.pages;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async ringAddRequest(info) {
      const sn = info.sn;
      if (!sn) {
        this.$toast.showRed("设备SN不能为空");
        return;
      }
      if (sn.length > 15) {
        this.$toast.showRed("设备SN不能超过15位");
        return;
      }
      try {
        const params = {
          hospitalId: this.hospitalID,
          ringSn: sn,
        };
        await this.$api.addRing(params);
        this.$toast.showGreen("指环添加成功");
        this.isShowAddRing = false;
        this.keyword = undefined;
        this.currentPage = 1;
        this.getRingList();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.system {
  font-size: 19px;
  width: 100%;
}
</style>
