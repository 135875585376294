import { DateTool } from "@js/date-tool.js";

class ReportModel {
  constructor(model) {
    this.model = model;
    //报告类型
    this.reportTemplateType = this._initReportTemplateType();

    //报告显示信息
    this.isCombineBloodOxygenAndPulse = !!parseInt(
      this.model.reportInfo.bloodPulse
    );
    this.isCombinePeriodAndEvent = !!parseInt(this.model.reportInfo.stageEvent);
    this.isPage2BreakAtPosition1 = this._initIsPage2BreakAtPosition1();
    this.isPage3BreakAtPosition1 = this._initIsPage3BreakAtPosition1();
    this.isShowAverageBreathRate = this._initIsShowAverageBreathRate();
    // 报告信息
    this.reportModeType = this._initReportModeType();
    this.hospitalIcon = this._initHospitalIcon();
    this.reportTitleArray = this._initReportTitleArray();
    this.reportSubtitle = this._initReportSubtitle();
    this.reportId = this._initReportId();
    //操作员信息
    this.initialOperator = this._initInitialOperator();
    this.initialReviewer = this._initInitialReviewer();
    // 用户信息
    this.initialPatientName = this._initInitialPatientName();
    this.initialPatientHeight = this._initInitialPatientHeight();
    this.initialPatientAge = this._initInitialPatientAge();
    this.initialPatientDepartment = this._initInitialPatientDepartment();
    this.initInitialMobile = this._initInitialMobile();
    this.initialPatientAdmissionNumber =
      this._initInitialPatientAdmissionNumber();
    this.initialPatientGender = this._initInitialPatientGender();
    this.initialPatientBMI = this._initInitialPatientBMI();
    this.initialPatientWeight = this._initInitialPatientWeight();
    this.initialPatientBedNumber = this._initInitialPatientBedNumber();
    this.initialPatientDiagnoseNumber =
      this._initInitialPatientDiagnoseNumber();
    this.initialPatientNeckCircumference =
      this._initInitialPatientNeckCircumference();
    //时间原始值（后面计算的基础）
    this.startRecordRawTime = this._initStartRecordRawTime(); //仪器开始记录时间（体动占比、体动幅度图表开始时间）
    this.ringStartRecordRawTime = this._initRingStartRecordRawTime(); //戒指开始记录时间（血氧、脉率图表开始时间）
    this.startPeriodRawTime = this._initStartPeriodRawTime(); //睡眠分期开始时间（分期趋势、呼吸事件开始时间）
    this.startTrendRawTime = this._initStartTrendRawTime(); //呼吸趋势开始时间（呼吸率开始时间）
    this.startSleepRawTime = this._initStartSleepRawTime(); //开始入睡时间
    this.stopRecordRawTime = this._initStopRecordRawTime(); //结束记录时间
    this.stopPeriodRawTime = this._initStopPeriodRawTime(); //睡眠分期结束时间
    this.stopSleepRawTime = this._initStopSleepRawTime(); //结束睡眠时间
    this.startMonitorRawTime = this._initStartMonitorRawTime(); //开始监测时间（所有图表的结束时间）
    this.stopMonitorRawTime = this._initStopMonitorRawTime(); //结束监测时间（所有图表的结束时间）
    //数据摘要
    this.ahiTitle = this._initahiTitle(); //AHI或OAHI（部分儿童）
    this.ahi = this._initahi();
    this.ai = this._initai();
    this.leavebedtimes = this._initLeavebedtimes();
    this.breathRateArray = this._initBreathRateArray();
    this.maxBreathRate = this._initMaxBreathRate();
    this.averageBreathRate = this._initAverageBreathRate();
    this.minBreathRate = this._initMinBreathRate();
    //睡眠时间统计
    this.startRecordDate = this._initStartRecordDate();
    this.startRecordTime = this._initStartRecordTime();
    this.startRecordFullTime = this._initStartRecordFullTime();
    this.startSleepTime = this._initStartSleepTime();
    this.createDate = this._initCreateDate(); //和stopRecord取同一个时间
    this.stopRecordTime = this._initStopRecordTime();
    this.stopRecordFullTime = this._initStopRecordFullTime();
    this.stopSleepTime = this._initStopSleepTime();
    this.sleepEfficiency = this._initSleepEfficiency();
    this.sleepTime = this._initSleepTime();
    this.totalSleepMinutes = this._initTotalSleepMinutes();
    //睡眠呼吸事件
    this.averageApneaTime = this._initAverageApneaTime(); //平均暂停和低通气时间
    this.maxApneaTime = this._initMaxApneaTime(); //最长暂停和低通气时间
    this.maxApneaTip = this._initMaxApneaTip(); //最长暂停和低通气发生时间
    this.breathEventCount = this._initBreathEventCount(); //总呼吸事件数
    this.breathEventTime = this._initBreathEventTime(); //总呼吸事件时间
    this.breathEventRate = this._initBreathEventRate(); //占总记录时间
    this.beohCnt = this._initBEOHCnt(); //阻塞及低通气事件数
    this.beoCnt = this._initBEOCnt(); //阻塞事件数
    this.behCnt = this._initBEHCnt(); //低通气事件数
    this.csaCount = this._initcsaCount(); //中枢性呼吸事件次数
    this.msaCount = this._initmsaCount(); //混合性呼吸事件次数
    //睡眠分期时间
    this.awakeTime = this._initAwakeTime();
    this.awakeRatio = this._initAwakeRatio();
    this.remTime = this._initremTime();
    this.remRatio = this._initremRatio();
    this.lightSleepTime = this._initLightSleepTime();
    this.lightSleepRatio = this._initLightSleepRatio();
    this.deepSleepTime = this._initDeepSleepTime();
    this.deepSleepRatio = this._initDeepSleepRatio();
    this.pieArray = this._initPieArray();
    //血氧统计
    this.averageBloodOxygenSaturation =
      this._initAverageBloodOxygenSaturation(); //平均血氧饱和度
    this.minBloodOxygenSaturation = this._initMinBloodOxygenSaturation(); //最低血氧饱和度
    this.oxygenRadiationTimes = this._initOxygenRadiationTimes(); //氧减次数
    this.oxygenRadiationIndex = this._initOxygenRadiationIndex(); //氧减指数
    this.bloodOxygen95LastingTime = `${parseInt(
      this._initBloodOxygen95LastingTime() / 60
    )}`;
    this.bloodOxygen95Rate = this._initBloodOxygen95Rate();
    this.bloodOxygen90LastingTime = `${parseInt(
      this._initBloodOxygen90LastingTime() / 60
    )}`;
    this.bloodOxygen90Rate = this._initBloodOxygen90Rate();
    this.bloodOxygen85LastingTime = `${parseInt(
      this._initBloodOxygen85LastingTime() / 60
    )}`;
    this.bloodOxygen85Rate = this._initBloodOxygen85Rate();
    this.bloodOxygen80LastingTime = `${parseInt(
      this._initBloodOxygen80LastingTime() / 60
    )}`;
    this.bloodOxygen80Rate = this._initBloodOxygen80Rate();
    //脉率统计
    this.averagePulse = this._initAveragePulse();
    this.maxPulse = this._initMaxPulse();
    this.minPulse = this._initMinPulse();
    //AHI
    this.ahiDegreeArray = this._initahiDegreeArray();
    //评估建议
    this.ahiLevel = this._initahiLevel();
    this.evaluateInfo = this._initEvaluateInfo();
    this.isEditable = this._initIsEditable();
    this.conclusion = this._initConclusion();
    //图表
    this.timeTickArray = this._initTimeTickArray();
    this.secondTimeArray = this._composeTimeArray(1);
    this.halfMinuteTimeArray = this._composeTimeArray(30);
    this.minuteTimeArray = this._composeTimeArray(60);
    //是否分别展示阻塞及低通气
    this.isSeprateDisplayBEOH = !!this.model.hyponea;
    //各图表数据为了省内存使用get方法
  }

  _initReportTemplateType() {
    return this.model.reportInfo.reportTemplateType;
  }

  _initIsPage2BreakAtPosition1() {
    return this.isCombineBloodOxygenAndPulse != true;
  }

  _initIsPage3BreakAtPosition1() {
    return !this.isCombineBloodOxygenAndPulse && !this.isCombinePeriodAndEvent;
  }

  _initIsShowAverageBreathRate() {
    return this.model.reportInfo.hospitalId == 351; //浙江大学医学院附属妇产科医院
  }

  _initHospitalIcon() {
    return this.model.reportInfo.reportLogo;
  }

  // 报告抬头第一行
  _initReportTitleArray() {
    return this.model.reportInfo.reportTitle.split(','); //多医院分行显示（天水市中西医结合医院）
  }

  // 报告抬头第二行
  _initReportSubtitle() {
    let rawSubtitle = this.model.reportInfo.reportSubtitle
    const childModeStr = (this.reportModeType == DEVICE_MONITOR_MODE.儿童模式) ? "（儿童模式）" : ""
    return rawSubtitle + childModeStr
  }

  _initReportModeType() {
    return this.model.reportInfo.modeType;
  }

  _initReportId() {
    return this.model.reportInfo.id;
  }

  _initCreateDate() {
    return DateTool.timestampToStr(this.stopRecordRawTime, "Y年M月D日");
  }

  _initInitialOperator() {
    return this.model.userInfo.report;
  }

  _initInitialReviewer() {
    return this.model.userInfo.examine;
  }

  _initInitialPatientName() {
    return this.model.userInfo.name;
  }

  _initInitialPatientNeckCircumference() {
    return this.model.userInfo.neckCircumference;
  }

  _initInitialPatientHeight() {
    return this.model.userInfo.height;
  }

  _initInitialPatientAge() {
    return `${this.model.userInfo.age}`;
  }

  _initInitialPatientDepartment() {
    let placeholderDepartemt;
    switch (this.reportTemplateType) {
      case REPORT_TEMPLATE.台州恩泽医疗中心集团恩泽医院:
        placeholderDepartemt = "健康管理中心";
        break;
      case REPORT_TEMPLATE.北京大学深圳医院呼吸内科睡眠检测中心:
      case REPORT_TEMPLATE.山东省立医院集团鲁东医院:
        placeholderDepartemt = "呼吸内科";
        break;
      case REPORT_TEMPLATE.舟山市第二人民医院:
        placeholderDepartemt = "四病区";
        break;
      case REPORT_TEMPLATE.深圳市儿童医院:
      case REPORT_TEMPLATE.西安交通大学附属儿童医院睡眠监测中心:
        placeholderDepartemt = "耳鼻咽喉头颈外科"
        break;
      default:
        break;
    }
    return this.model.userInfo.department ? this.model.userInfo.department : placeholderDepartemt;
  }

  _initInitialMobile() {
    return this.model.userInfo.mobile;
  }

  _initInitialPatientAdmissionNumber() {
    return this.model.userInfo.zyh;
  }

  _initInitialPatientGender() {
    if (this.model.userInfo.sex == 1) {
      return "男";
    } else if (this.model.userInfo.sex == 2) {
      return "女";
    } else {
      return this.model.userInfo.sex;
    }
  }

  _initInitialPatientBMI() {
    return this.model.userInfo.bmi;
  }

  _initInitialPatientWeight() {
    return this.model.userInfo.weight;
  }

  _initInitialPatientBedNumber() {
    return this.model.userInfo.bch;
  }

  _initInitialPatientDiagnoseNumber() {
    return this.model.userInfo.mzh;
  }

  _initahi() {
    return this.model.statistical.ahi;
  }

  _initai() {
    return this.model.statistical.ai;
  }

  _initahiTitle() {
    if (
      this.reportModeType == DEVICE_MONITOR_MODE.儿童模式 &&
      this.model.statistical.oahi != ""
    ) {
      return "OAHI"; //oahi和ahi值都是一样的，只是名称不同
    } else {
      return "AHI";
    }
  }

  _initahiLevel() {
    return this.model.reportInfo.ahiLevel;
  }

  _initLeavebedtimes() {
    return this.model.statistical.leaveBedTimes;
  }

  _initBreathRateArray() {
    return this.model.detailedArr.bmprlist
      .map((item) => {
        return item[0];
      })
      .filter((item) => {
        return item != 0;
      });
  }

  _initMaxBreathRate() {
    if (this.breathRateArray.length == 0) {
      return "";
    } else {
      return `${Math.max(...this.breathRateArray)}`;
    }
  }

  _initAverageBreathRate() {
    const rawArray = this.model.detailedArr.bmprlist.map((item) => {
      return item[0];
    });
    const validArray = rawArray.filter((item) => {
      return item != 0;
    });
    let sum = 0;
    for (const item of validArray) {
      sum += item;
    }
    const average = sum / validArray.length;
    return `${average.toFixed(0)}`;
  }

  _initMinBreathRate() {
    if (this.breathRateArray.length == 0) {
      return "";
    } else {
      return `${Math.min(...this.breathRateArray)}`;
    }
  }

  _initStartRecordTime() {
    return DateTool.timestampToStr(this.startRecordRawTime, "h：m");
  }

  _initStartRecordFullTime() {
    return DateTool.timestampToStr(this.startRecordRawTime, "Y/M/D h：m：s");
  }

  _initStartRecordDate() {
    return DateTool.timestampToStr(this.startRecordRawTime, "Y年M月D日");
  }

  _initStartSleepTime() {
    const time = DateTool.timestampToStr(this.startSleepRawTime, "h：m");
    return time;
  }

  _initStopRecordTime() {
    const time = DateTool.timestampToStr(this.stopRecordRawTime, "h：m");
    return time;
  }

  _initStopRecordFullTime() {
    const time = DateTool.timestampToStr(
      this.stopRecordRawTime,
      "Y/M/D h：m：s"
    );
    return time;
  }
  _initStopSleepTime() {
    const time = DateTool.timestampToStr(this.stopSleepRawTime, "h：m");
    return time;
  }
  _initSleepEfficiency() {
    return this.model.statistical.sleepEfficiency;
  }

  _initSleepTime() {
    const totalMinutes =
      this.model.statistical.remTime +
      this.model.statistical.lightSleepTime +
      this.model.statistical.deepSleepTime;
    const hour = parseInt(totalMinutes / 60);
    const minute = totalMinutes - hour * 60;
    return `${hour}小时${minute}分钟`;
  }

  _initTotalSleepMinutes() {
    return (
      this.model.statistical.remTime +
      this.model.statistical.lightSleepTime +
      this.model.statistical.deepSleepTime
    );
  }

  _initAverageApneaTime() {
    return `${this.model.statistical.beMeanLen}`;
  }

  _initMaxApneaTime() {
    return `${this.model.statistical.beMaxLen}`;
  }

  _initMaxApneaTip() {
    if (this.model.statistical.beMeanlen == 0) {
      return `该事件发生于：--`;
    } else {
      //事件发生时间早于开始入睡时间（精确到分钟）
      if (this.model.statistical.beMaxLenTime < this.startSleepRawTime - 60) {
        return `该事件发生于：--`;
      } else {
        const time = DateTool.timestampToStr(
          this.model.statistical.beMaxLenTime,
          "h：m"
        );
        return `该事件发生于：${time}`;
      }
    }
  }

  _initBreathEventCount() {
    return `${this.model.statistical.beCnt}`;
  }

  _initBreathEventTime() {
    return `${this.model.statistical.beTotalTime}`;
  }

  _initBreathEventRate() {
    return `${this.model.statistical.beTotalRate}`;
  }

  _initBEOHCnt() {
    return `${this.model.statistical.beOhCnt}`;
  }

  _initBEOCnt() {
    return `${this.model.statistical.beoCnt}`;
  }

  _initBEHCnt() {
    return `${this.model.statistical.behCnt}`;
  }

  _initcsaCount() {
    return `${this.model.statistical.becCnt}`;
  }

  _initmsaCount() {
    return `${this.model.statistical.bemCnt}`;
  }

  _initAwakeTime() {
    return `${this.model.statistical.wakeTime}`;
  }

  _initAwakeRatio() {
    return this.model.statistical.wakeRate;
  }

  _initremTime() {
    return `${this.model.statistical.remTime}`;
  }

  _initremRatio() {
    return `${this.model.statistical.remRate}`;
  }

  _initLightSleepTime() {
    return `${this.model.statistical.lightSleepTime}`;
  }

  _initLightSleepRatio() {
    return `${this.model.statistical.lightSleepRate}`;
  }

  _initDeepSleepTime() {
    return `${this.model.statistical.deepSleepTime}`;
  }

  _initDeepSleepRatio() {
    return `${this.model.statistical.deepSleepRate}`;
  }

  _initPieArray() {
    return [
      {
        type: "清醒期",
        value: this.model.statistical.wakeTime
      },
      {
        type: "眼动期",
        value: this.model.statistical.remTime

      },
      {
        type: "浅睡期",
        value: this.model.statistical.lightSleepTime
      },
      {
        type: "深睡期",
        value: this.model.statistical.deepSleepTime
      },
    ];
  }
  _initAverageBloodOxygenSaturation() {
    return `${this.model.statistical.spo2avg}`;
  }

  _initMinBloodOxygenSaturation() {
    return `${this.model.statistical.spo2min}`;
  }

  _initOxygenRadiationTimes() {
    return `${this.model.statistical.diffThdLge3Cnts}`;
  }

  _initOxygenRadiationIndex() {
    return `${this.model.statistical.diffThdLge3Pr}`;
  }

  _initBloodOxygen95LastingTime() {
    return Number(this.model.statistical.spo2Less95time);
  }

  _initBloodOxygen95Rate() {
    return `${this.model.statistical.spo2Less9TimePercent}`;
  }

  _initBloodOxygen90LastingTime() {
    return Number(this.model.statistical.spo2Less90time);
  }

  _initBloodOxygen90Rate() {
    return `${this.model.statistical.spo2Less90TimePercent}`;
  }

  _initBloodOxygen85LastingTime() {
    return Number(this.model.statistical.spo2Less85time);
  }

  _initBloodOxygen85Rate() {
    return `${this.model.statistical.spo2Less85TimePercent}`;
  }

  _initBloodOxygen80LastingTime() {
    return Number(this.model.statistical.spo2Less80time);
  }

  _initBloodOxygen80Rate() {
    return `${this.model.statistical.spo2Less80TimePercent}`;
  }

  _initAveragePulse() {
    return `${this.model.statistical.prAvg}  bpm`;
  }

  _initMaxPulse() {
    return `${this.model.statistical.prMax}  bpm`;
  }

  _initMinPulse() {
    return `${this.model.statistical.prMin}  bpm`;
  }

  _initStartRecordRawTime() {
    return parseInt(this.model.statistical.startSleepTime / 1000);
  }

  _initRingStartRecordRawTime() {
    return this.model.statistical.ringTimeStart;
  }

  _initStartPeriodRawTime() {
    return (
      this.startRecordRawTime +
      this.model.statistical.startStatusTimeMinute * 60
    );
  }

  _initStartTrendRawTime() {
    return this.model.detailedArr.bmprlistInfo.startTime;
  }

  _initStartSleepRawTime() {
    return (
      this.startRecordRawTime + this.model.statistical.fallSleepTimeMinute * 60
    );
  }

  _initStopRecordRawTime() {
    return (
      this.startRecordRawTime + this.model.statistical.extraCheckTimeMinute * 60
    );
  }

  _initStopPeriodRawTime() {
    return (
      this.startRecordRawTime + this.model.statistical.endStatusTimeMinute * 60
    );
  }

  _initStartMonitorRawTime() {
    return Math.max(
      this.startSleepRawTime - 15 * 60,
      this.startRecordRawTime,
      this.startPeriodRawTime
    );
  }

  _initStopSleepRawTime() {
    if (this.model.statistical.endSleepTimeMinute == 0) {
      //睡眠分期数组尾部0值个数
      let zeroCount = 0;
      const sleepStagesReverseArray =
        this.model.detailedArr.sleepdata.reverse();
      for (const item of sleepStagesReverseArray) {
        if (item == 0) {
          zeroCount += 1;
        } else {
          break;
        }
      }
      //真实的睡眠分期结束时间
      const endStatusTimeMinute =
        this.model.statistical.endStatusTimeMinute - zeroCount;
      return this.startRecordRawTime + endStatusTimeMinute * 60;
    } else {
      return (
        this.startRecordRawTime + this.model.statistical.endSleepTimeMinute * 60
      );
    }
  }

  _initStopMonitorRawTime() {
    return Math.min(
      this.stopRecordRawTime,
      this.stopSleepRawTime + 15 * 60,
      this.stopPeriodRawTime
    );
  }

  //睡眠血氧趋势图表数据
  get bloodOxygenChartDataArray() {
    const step = 1;
    const startTime = this.ringStartRecordRawTime;
    const valueArray = this.model.detailedArr.spo2arr.map((item) => {
      return item == 0 ? undefined : item;
    });
    return this._composeDataArray(step, startTime, valueArray);
  }

  //睡眠脉率趋势图表数据
  get pulseChartDataArray() {
    const step = 1;
    const startTime = this.ringStartRecordRawTime;
    const valueArray = this.model.detailedArr.prarr.map((item) => {
      return item == 0 ? undefined : item;
    });
    return this._composeDataArray(step, startTime, valueArray);
  }

  //睡眠呼吸趋势图表数据
  get breathChartDataArray() {
    const step = 60;
    const startTime = this.startTrendRawTime;
    const valueArray = this.model.detailedArr.bmprlist.map((item) => {
      return item[0] == 0 ? undefined : item[0];
    });
    return this._composeDataArray(step, startTime, valueArray);
  }

  //睡眠分期趋势图表数据
  get breathPeriodChartDataArray() {
    const step = this.ringSN.startsWith("M11B") ? 30 : 60;
    const startTime = this.startPeriodRawTime;
    const valueArray = this.model.detailedArr.sleepdata.map((item, index) => {
      switch (item) {
        case 6: //离床
          //判断是否单独的一个6
          const lastItem = this.model.detailedArr.sleepdata[index - 1];
          const nextItem = this.model.detailedArr.sleepdata[index + 1];
          if (lastItem != 6 && nextItem != 6) {
            return mapValue(lastItem);
          } else {
            return null;
          }
        default:
          return mapValue(item);
      }
    });
    const dataArray = this._composeDataArray(step, startTime, valueArray);
    return dataArray;

    function mapValue(rawValue) {
      switch (rawValue) {
        case 0: //清醒
          return 4;
        case 2: //眼动
          return 3;
        case 3: //浅睡
          return 2;
        case 4: //深睡
          return 1;
        default:
          return null;
      }
    }
  }

  get breathLeavePeriodChartDataArray() {
    const step = this.ringSN.startsWith("M11B") ? 30 : 60;
    const startTime = this.startPeriodRawTime;
    const valueArray = this.model.detailedArr.sleepdata.map((item, index) => {
      switch (item) {
        case 6: //离床
          //判断是否单独的一个6
          const lastItem = this.model.detailedArr.sleepdata[index - 1];
          const nextItem = this.model.detailedArr.sleepdata[index + 1];
          if (lastItem != 6 && nextItem != 6) {
            return mapValue(lastItem);
          } else {
            return 4;
          }
        default:
          return mapValue(item);
      }
    });
    const dataArray = this._composeDataArray(step, startTime, valueArray);
    return dataArray;

    function mapValue(rawValue) {
      switch (rawValue) {
        case 6: //离床
          return 4;
        default:
          return null;
      }
    }
  }

  // 睡眠分期趋势图表数据  以秒为单位补全数据
  get breathPeriodChartDataArray2() {
    const isSecond = true; // 使用秒作为时间单位
    const startTime = this.startPeriodRawTime;
    const valueArray = [];

    let secondIndex = 0;
    for (
      let minuteIndex = 0;
      minuteIndex < this.model.detailedArr.sleepdata.length;
      minuteIndex++
    ) {
      const minuteValue = this.model.detailedArr.sleepdata[minuteIndex];
      for (let i = 0; i < 60; i++) {
        let sleepStageValue;
        switch (minuteValue) {
          case 0: // 清醒
            sleepStageValue = 4;
            break;
          case 2: // 眼动
            sleepStageValue = 3;
            break;
          case 3: // 浅睡
            sleepStageValue = 2;
            break;
          case 4: // 深睡
            sleepStageValue = 1;
            break;
          default:
            sleepStageValue = 0;
            break;
        }
        valueArray.push({
          xValue: (startTime + secondIndex) * 1000,
          yValue: sleepStageValue,
        });
        secondIndex++;
      }
    }

    return valueArray;
  }

  //睡眠呼吸事件图表数据
  get breathEventChartDataArray() {
    const step = 1;
    const startTime = this.startPeriodRawTime;
    let valueArray = new Array(this.secondTimeArray.length).fill(0);
    for (const item of this.model.detailedArr.breatheventvect) {
      const index = this.secondTimeArray.indexOf(item[0]);
      valueArray[index] = item[1];
    }
    return this._composeDataArray(step, startTime, valueArray);
  }

  //睡眠体动占比图表数据
  get moveRateChartDataArray() {
    const step = 60;
    const startTime = this.startRecordRawTime;
    const valueArray = this.model.detailedArr.bodymovelist.map((item) => {
      return item[0];
    });
    return this._composeDataArray(step, startTime, valueArray);
  }

  //睡眠体动幅度图表数据
  get moveRangeChartDataArray() {
    const step = 60;
    const startTime = this.startRecordRawTime;
    const valueArray = this.model.detailedArr.bodymovelist.map((item) => {
      return item[1];
    });
    return this._composeDataArray(step, startTime, valueArray);
  }

  //戒指sn
  get ringSN() {
    return this.model.reportInfo.sn;
  }

  _initTimeTickArray() {
    //XTickArray
    let secondPerHour = 60 * 60; //每小时的毫数
    let startTimestamp = this.startMonitorRawTime;
    let startIntTimestamp = //开始时前的整点时间戳
      Math.floor(startTimestamp / secondPerHour) * secondPerHour;
    let endTimestamp = this.stopMonitorRawTime;
    let endIntTimestamp = //结束时后的整点时间戳
      Math.ceil(endTimestamp / secondPerHour) * secondPerHour;
    let XTickCount = (endIntTimestamp - startIntTimestamp) / secondPerHour;
    let XTickArray = [];
    //加入每个整点时间戳
    for (let i = 0; i <= XTickCount; i++) {
      let intTimeStamp = startIntTimestamp + i * secondPerHour;
      XTickArray.push(intTimeStamp);
    }
    //真实开始时间替换首位整点时间戳
    XTickArray[0] = startTimestamp;
    //如果第二位整点时间戳与真实开始时间相差不到30分钟，剔除
    if (XTickArray[1] - XTickArray[0] < 0.5 * secondPerHour) {
      XTickArray.splice(1, 1);
    }
    //真实结束时间替换末位整点时间戳
    XTickArray[XTickArray.length - 1] = endTimestamp;
    //如果倒数第二位整点时间戳与真实结束时间相差不到30分钟，剔除
    if (
      XTickArray[XTickArray.length - 1] - XTickArray[XTickArray.length - 2] <
      0.5 * secondPerHour
    ) {
      XTickArray.splice(XTickArray.length - 2, 1);
    }
    return XTickArray.map((item) => {
      return item * 1000;
    });
  }

  _initahiDegreeArray() {
    if (this.reportModeType == DEVICE_MONITOR_MODE.儿童模式) {
      if (
        this.model.reportInfo.standard ==
        CHILD_STANDDARD_TYPE_CODE["2007版中国指南"]
      ) {
        return AHI_DEGREE_ARRAY.中国儿童;
      } else {
        return AHI_DEGREE_ARRAY.美国儿童;
      }
    } else {
      return AHI_DEGREE_ARRAY.成人;
    }
  }

  _initEvaluateInfo() {
    return this.model.reportInfo.evaluate;
  }

  _initIsEditable() {
    return this.evaluateInfo.type != REPORT_SUGGESTION_TYPE.智能评估;
  }

  _initConclusion() {
    if (this.evaluateInfo.customize == null) {
      return this.evaluateInfo.customize;
    } else {
      switch (this.evaluateInfo.type) {
        case REPORT_SUGGESTION_TYPE.空白模板:
          return "";
        default:
          return this.evaluateInfo.customize;
      }
    }
  }

  _composeTimeArray(step) {
    let timeArray = [];
    let maxIndex = (this.stopMonitorRawTime - this.startMonitorRawTime) / step;
    for (let index = 0; index <= maxIndex; index++) {
      const timeItem = this.startMonitorRawTime + index * step;
      timeArray.push(timeItem);
    }
    return timeArray;
  }

  _composeDataArray(step, startTime, valueArray) {
    let dataArray = [];
    let timeArray = [];
    switch (step) {
      case 1:
        timeArray = this.secondTimeArray;
        break;
      case 30:
        timeArray = this.halfMinuteTimeArray;
        break;
      case 60:
        timeArray = this.minuteTimeArray;
        break;

      default:
        break;
    }
    timeArray.forEach((item) => {
      let index = parseInt((item - startTime) / step);
      let dataItem = {
        xValue: item * 1000,
        yValue: valueArray[index],
      };
      dataArray.push(dataItem);
    });
    return dataArray;
  }
}

export { ReportModel };
