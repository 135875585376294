<template>
  <div class="monitor">
    <div class="monitor-content">
      <page-list-container
        class="monitor-content-bottom"
        :total="total"
        :pageSize="pageSize"
        :inputCurrentPage="currentPage"
        @onPageChange="onPageChange"
        @onSizeChange="onSizeChange"
      >
        <item-bar />
        <div class="monitor-content-bottom-list">
          <cell
            class="monitor-content-bottom-list-item"
            v-for="(item, index) of deviceArray"
            :isSelected="selectedID === item.id"
            :key="index"
            :model="item"
            :canDeleteDevice="canDeleteDevice"
            @onSelectClick="onCellSelectClick"
            @onStopClick="onStopClick"
            @onAddUserClick="onAddUserClick"
            @onDeleteClick="onDeleteClick"
            @onEditClick="onEditClick"
          ></cell>
        </div>
      </page-list-container>
    </div>
    <device-add
      v-if="isShowAddDevice"
      @onCloseClick="onAddDeviceCloseClick"
      @onConfirmClick="onAddDeviceConfirmClick"
    ></device-add>
    <device-edit
      :model="selectedModel"
      v-if="isShowEditDevice"
      @onCloseClick="onEditDeviceCloseClick"
      @onConfirmClick="onEditDeviceConfirmClick"
    ></device-edit>
  </div>
</template>

<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import KeywordSearch from "@c/common/keyword-search.vue";
import ItemBar from "./item-bar.vue";
import Cell from "./cell.vue";
import Pagination from "@c/common/pagination2.vue";
import DeviceAdd from "./device-add/device-add.vue";
import DeviceEdit from "./device-edit.vue";

export default {
  components: {
    PageListContainer,
    KeywordSearch,
    ItemBar,
    Cell,
    Pagination,
    DeviceAdd,
    DeviceEdit,
  },

  props: {
    canDeleteDevice: Boolean,
  },

  data: function () {
    return {
      //deviceList
      deviceArray: [],
      total: undefined, // 显示总条数
      pageSize: undefined, // 每页显示条数
      currentPage: 1, // 当前的页数
      //keywords
      keywords: "",
      //cell
      selectedModel: undefined,
      //add-device
      isShowAddDevice: false,
      //edit-device
      isShowEditDevice: false,
    };
  },

  computed: {
    hospitalID() {
      return this.$store.state.selectedHospital.hospitalid;
    },

    selectedID() {
      return this.selectedModel == undefined
        ? undefined
        : this.selectedModel.id;
    },
  },

  watch: {
    hospitalID() {
      this.currentPage = 1;
      this.deviceListRequest();
    },
  },

  mounted() {
    this.pageSize = bestPageSizeComputer();
    this.deviceListRequest();
  },

  methods: {
    //keyword-search
    onSearchClick(value) {
      this.currentPage = 1;
      this.keywords = value;
      this.deviceListRequest();
    },

    onAddDeviceClick() {
      if (this.hospitalID === null) {
        this.$toast.showRed(MSG.pickHospitalRemind);
        return;
      }
      this.isShowAddDevice = true;
    },

    //pagination
    onPageChange(currentPage) {
      this.currentPage = currentPage;
      this.deviceListRequest();
    },

    onSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.deviceListRequest();
    },
    //cell
    onCellSelectClick(model) {
      this.selectedModel = model;
    },

    onStopClick() {
      this.$alert.show("设备操作", "是否要终止监测？", () => {
        this.deviceStopRequest();
      });
    },

    onAddUserClick() {
      this.$emit("onAddUserClick", this.selectedModel);
    },

    onDeleteClick() {
      this.$alert.show("设备操作", "是否要删除设备？", () => {
        this.deviceDeleteRequest();
      });
    },

    onEditClick() {
      if (
        this.selectedModel.userStatusDesc === "维保" ||
        this.selectedModel.userStatusDesc === "维修" ||
        this.selectedModel.userStatusDesc === "闲置"
      ) {
        this.isShowEditDevice = true;
      } else {
        this.$toast.showRed(MSG.deviceInUse);
      }
    },

    // add-device
    onAddDeviceCloseClick() {
      this.isShowAddDevice = false;
    },

    onAddDeviceConfirmClick(info) {
      this.isShowAddDevice = false;
      this.deviceAddRequest(info);
    },

    // edit-device
    onEditDeviceCloseClick() {
      this.isShowEditDevice = false;
    },

    onEditDeviceConfirmClick(info) {
      this.isShowEditDevice = false;
      this.deviceEditRequest(info);
    },

    // request
    async deviceListRequest() {
      try {
        const params = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          sn: this.keywords,
          hospitalId: this.hospitalID,
        };
        const response = await this.$api.getDeviceList(params);
        this.total = response.total;
        this.deviceArray = response.list;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async deviceDeleteRequest() {
      try {
        const params = {
          id: this.selectedModel.id,
        };
        await this.$api.deleteDevice(params);
        this.$toast.showGreen(MSG.deviceDeleteSucceed);
        this.deviceListRequest();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async deviceStopRequest() {
      try {
        const params = {
          id: this.selectedModel.id,
        };
        await this.$api.stopDevice(params);
        this.deviceListRequest();
        this.$toast.showGreenLink("操作成功，", "点此马上去回收设备！", () => {
          console.log("🐭");
          this.$emit("onRecycleDeviceClick", this.selectedModel);
        });
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async deviceAddRequest(info) {
      try {
        const params = {
          sn: info.sn,
          hospitalId: this.hospitalID,
          remark: info.remark,
          roomNo: info.room,
          scene: info.isInner ? SCENE_CODE.院内 : SCENE_CODE.院外,
        };
        await this.$api.addDevice(params);
        this.$toast.showGreen(MSG.deviceAddSucceed);
        this.currentPage = 1;
        this.keywords = "";
        this.deviceListRequest();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async deviceEditRequest(info) {
      try {
        const params = {
          id: this.selectedModel.id,
          roomNo: info.room,
          remark: info.remark,
          scene: info.isInner ? SCENE_CODE.院内 : SCENE_CODE.院外,
        };
        await this.$api.editDevice(params);
        this.$toast.showGreen(MSG.deviceEditSucceed);
        this.deviceListRequest();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.monitor {
  &-content {
    background-color: white;
    border-radius: rem(2);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-top {
      font-size: rem(16);
      width: 100%;
      height: rem(59);
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      &-left {
        display: flex;

        &-title {
          color: #325fac;
          font-size: rem(14);
          border-bottom: rem(2) solid #325fac;
          width: rem(100);
          height: rem(32);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-add-device {
          color: white;
          background-color: #325fac;
          font-size: rem(14);
          width: rem(88);
          height: rem(32);
          border-radius: 4px;
          margin-left: rem(74);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-bottom {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-list {
        width: 100%;
      }

      &-empty-info {
        color: rgba($color: black, $alpha: 0.85);
        font-size: rem(14);
        width: 100%;
        margin-top: rem(100);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-pagination {
        width: 100%;
        height: rem(90);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
