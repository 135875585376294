<template>
    <div class="title">
        <div class="title-line"></div>
        <div class="title-content1">

            <div class="title-content1-subtitle">一般信息</div>
        </div>
        <div class="title-content2">
            <div class="title-content2-name">
                <p class="title-content2-name-title">姓名</p>
                <input class="title-content2-name-input" type="text" v-model="trueName"
                    @input="onInputInput('姓名', trueName)" />
            </div>
            <div class="title-content2-sex">
                <p class="title-content2-sex-title">性别</p>
                <input class="title-content2-sex-input-unit" type="text" v-model="sex"
                    @input="onInputInput('性别', sex)" />
            </div>

            <div class="title-content2-age">
                <p class="title-content2-age-title">年龄</p>
                <input class="title-content2-age-input-unit" type="text" v-model="age"
                    @input="onInputInput('年龄', age)" />
                <p class="title-content2-age-unit">岁</p>
            </div>


        </div>
        <div class="title-content3">
            <div class="title-content3-height">
                <p class="title-content3-height-title">身高</p>
                <input class="title-content3-height-input-unit" type="text" v-model="height"
                    @input="onInputInput('身高(cm)', height)" />
                <p class="title-content3-height-unit">cm</p>
            </div>
            <div class="title-content3-weight">
                <p class="title-content3-weight-title">体重</p>
                <input class="title-content3-weight-input-unit" type="text" v-model="weight"
                    @input="onInputInput('体重(Kg)', weight)" />
                <p class="title-content3-weight-unit">kg</p>
            </div>
            <div class="title-content3-bmi">
                <p class="title-content3-bmi-title">BMI</p>
                <p class="title-content3-bmi-bmi">{{patientBMI}}</p>
                <p class="title-content3-bmi-unit">kg/m²</p>
            </div>


        </div>
        <div class="title-content4" >   
            <div class="title-content4-department" >
                <p class="title-content4-department-title">电话</p>
                <input class="title-content4-department-input-unit" type="text" v-model="mobile"
                    @input="onInputInput('手机号', mobile)" />
            </div>
            <div class="title-content4-records">
                <p class="title-content4-records-title">科室</p>
                <input class="title-content4-records-input-unit" type="text" v-model="department"
                    @input="onInputInput('科室', department)" />
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        model: Object,
        patientBMI: String,
    },
    data() {
        return {
            trueName: "",
            operator: "",
            reviewer: "",
            height: "",
            age: "",
            mzh: "",
            sex: "",
            weight: "",
            bmi: "",
            department: "",
            mobile: "",
            neckCircumference :""
        }
    },

    mounted() {
        this.updateData(this.model)
    },

    methods: {
        onInputInput(title, value) {
            this.$emit("onInputInput", title, value);
        },
        updateData(model) {
            this.trueName = model.initialPatientName;
            this.operator = model.initialOperator;
            this.reviewer = model.initialReviewer;
            this.height = model.initialPatientHeight;
            this.age = model.initialPatientAge;
            this.mzh = model.initialPatientDiagnoseNumber;
            this.sex = model.initialPatientGender;
            this.weight = model.initialPatientWeight;
            this.department = model.initialPatientDepartment;
            this.mobile = model.initInitialMobile;
            this.neckCircumference = model.initialPatientNeckCircumference;
        }
    },
    watch: {
        model(newValue) {
            this.updateData(newValue);
        },
    },

};
</script>

<style lang="scss" scoped>
.title {
    &-line {
        margin-top: rem(10);
        width: 100%;
        height: 0;
        border: 2px solid #000000;
    }

    &-content1 {
        margin-top: rem(10);
        font-size: rem(16);
        font-weight: bold;
        display: flex;

        &-input {
            font-size: rem(16);
            font-family: "songti";
            font-weight: bold;
            width: rem(91);
        }

        &-title {
            display: flex;
            align-items: center;
        }

        &-report {

            display: flex;

        }

        &-review {

            display: flex;
            margin-left: rem(10);
        }

        &-subtitle {
            // text-align: center;
            /*水平居中*/
            display: flex;
            align-items: center;
            font-size: rem(16);
            color: #0080FF;
            font-weight: 900;
        }


    }

    &-content2 {
        margin-top: rem(15);
        font-size: rem(16);
        font-family: "songti";
        display: flex;

        &-name {
            display: flex;
            &-title{
                font-weight: bold;
                display: flex;
                align-items: center;
            }
            &-input {
                
                font-family: "songti";
                width: rem(196);
                margin-left: rem(40);
                display: flex;
                align-items: center;
            }
            
        }


        &-sex {
            display: flex;
            &-title{
                font-weight: bold;
                display: flex;
                align-items: center;
            }
            &-input-unit{
                font-family: "songti";

                width: rem(201);
                margin-left: rem(40);
                display: flex;
                align-items: center;
            }
        }

        &-age {

            display: flex;
            &-title{
                font-weight: bold;
                display: flex;
                align-items: center;
            }
            &-input-unit{
                font-family: "songti";

                width: rem(33);
                margin-left: rem(40);
                display: flex;
                align-items: center;
            }
            &-unit{
                display: flex;
                align-items: center;
            }
        }

       

    }

    &-content3 {
        margin-top: rem(5);
        font-size: rem(16);
        font-family: "songti";
        display: flex;

        &-height {
            
            display: flex;
            &-title{
                font-weight: bold;
                display: flex;
                align-items: center;
            }
            &-input-unit{
                font-family: "songti";

                width: rem(50);
                margin-left: rem(40);
                display: flex;
                align-items: center;
            }
            &-unit{
          
                align-items: center;
                width:rem(130) ;
                display: flex;
                align-items: center;
            }
        }
        &-weight {
            margin-left: rem(15);
            display: flex;
            &-title{
                font-weight: bold;
                display: flex;
                align-items: center;
            }
            &-input-unit{
                font-family: "songti";
                width: rem(53);
                margin-left: rem(40);
                display: flex;
                align-items: center;
            }
            &-unit{
                display: flex;
                align-items: center;
                width:rem(140) ;
            }
        }


    

    

        &-bmi {
             margin-left: rem(10);
            display: flex;
            &-title{
                font-weight: bold;
                display: flex;
                align-items: center;
                
            }
            &-bmi {
                display: flex;
                align-items: center;
                margin-left: rem(48);
            
                font-size: rem(14);
                font-weight: 400;
            }

            &-unit {
                margin-left: rem(15);
                display: flex;
                align-items: center;
            }
        }

        &-department {

            display: flex;
        }
    }

    &-content4 {
        margin-top: rem(5);
        font-size: rem(16);
        font-family: "songti";
        display: flex;

        &-neck {
            
            display: flex;
            &-title{
                font-weight: bold;
                display: flex;
                align-items: center;
            }
            &-input-unit{
                width: rem(51);
                margin-left: rem(40);
                display: flex;
                align-items: center;
            }
            &-unit{
       
                width:rem(145) ;
                display: flex;
                align-items: center;
            }
        }
        &-department {
           
            display: flex;
            &-title{
                font-weight: bold;
                display: flex;
                align-items: center;
            }
            &-input-unit{
                font-family: "songti";
                width: rem(196);
                margin-left: rem(40);
                display: flex;
                align-items: center;
            }
            &-unit{
                display: flex;
                align-items: center;
                width:rem(162) ;
            }
        }


    

    

        &-records {
    
            display: flex;

            &-title{
                font-weight: bold;
                display: flex;
                align-items: center;
                
            }
            &-input-unit {
                font-family: "songti";
                display: flex;
                align-items: center;
                margin-left: rem(40);
    
                width: rem(76);
         
            }

            &-unit {
             
                display: flex;
                align-items: center;
            }
        }

        &-department {

            display: flex;
        }
    }
}
</style>
