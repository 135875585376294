<template>
  <div id="app">
    <router-view />
    <Loading class="loading" v-if="this.$store.state.loadingShow" />
  </div>
</template>

<script>
import { CacheTool } from "@js/cache-tool.js";
import { global } from "@js/global.js";
import Loading from "@c/common/Loading.vue";

// import VConsole from 'vconsole';

export default {
  components:{Loading},
  //在页面刷新时读取localStorage里的storeState信息
  created() {
    const storeState = CacheTool.getStoreState()
    if (storeState) {
      this.$store.replaceState(
        Object.assign(this.$store.state, CacheTool.getStoreState())
      );
    }
  },

  //浏览器同时只能允许一个账号在线
  mounted() {
    // const vConsole = new VConsole();
    global.token = CacheTool.getLoginInfo()?.token
    document.addEventListener("visibilitychange", this.checkNameExpired)
    //为八戒睡眠公众号（跨域了无法直接设置localStorage）留的口子，通过postMessage的方式传值
    window.addEventListener('message', this.receiveMessage, false)
  },

  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.checkNameExpired)
    document.removeEventListener("message", this.receiveMessage)
  },

  methods: {
    checkNameExpired() {
      if (document.hidden == false && global.token != CacheTool.getLoginInfo()?.token) {
        global.token = CacheTool.getLoginInfo()?.token
        window.location.reload()
      }
    },
    receiveMessage(event) {
      let origin = event.origin
      let data = event.data
      if (origin === 'https://wx.bajiesleep.cn') {
        if (data && data.userInfo) {
          localStorage.setItem('thirdLoginInfo', JSON.stringify(data.userInfo));
        }
      }
    }
  },



};
</script>


<style lang="scss" scoped>

@media screen and (max-width: rem(450)) {
  #app {
    zoom: 70%;
  }
}

.loading{
  @include z-index-max
}
</style>


