<template>
  <list-title-bar :titleArray="titleArray"></list-title-bar>
</template>

<script>
import ListTitleBar from "@c/operation-log/common/paging-list/list-title-bar.vue";

export default {
  components: { ListTitleBar },

  computed: {
    titleArray() {
      return [
        { name: "姓名", percent: "8%" },
        { name: "手机号", percent: "15%" },
        { name: "类型", percent: "10%" },
        { name: "所属机构", percent: "29%" },
        { name: "管理医院", percent: "10%" },
        { name: "设备权限", percent: "9%" },
        { name: "状态", percent: "9%" },
        { name: "操作", percent: "10%" },
      ];
    },
  },
};
</script>
