<template>
  <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
    <div class="organization-cell">
      <cell-unit :style="{ width: '8%' }" :title="memberName"></cell-unit>
      <cell-unit :style="{ width: '15%' }" :title="mobile"></cell-unit>
      <cell-unit :style="{ width: '10%' }" :title="type"></cell-unit>
      <cell-unit :style="{ width: '29%' }" :title="organization"></cell-unit>
      <cell-unit :style="{ width: '10%' }" :title="hospitalCount"></cell-unit>
      <cell-unit :style="{ width: '9%' }" :title="devicePriority"></cell-unit>
      <cell-unit :style="{ width: '9%' }" :title="status"></cell-unit>
      <cell-unit
        :style="{ width: '10%', cursor: 'pointer' }"
        color="#325FAC"
        :icon="require('@imgs/edit.png')"
        title="编辑"
        @onClick="onEditClick"
      ></cell-unit>
    </div>
  </list-cell>
</template>

<script>
import CellUnit from "@c/operation-log/common/paging-list/cell-unit.vue";
import ListCell from "@c/operation-log/common/paging-list/list-cell.vue";
export default {
  components: {
    ListCell,
    CellUnit,
  },

  props: {
    index: Number,
    model: Object,
    isSelected: Boolean,
  },

  computed: {
    memberName() {
      return placeholderText(this.model.trueName);
    },

    mobile() {
      return placeholderText(this.model.userName);
    },

    type() {
      return placeholderText(this.model.userTypeDesc);
    },

    organization() {
      return placeholderText(this.model.hospitalName);
    },

    hospitalCount() {
      return placeholderText(this.model.hospitalCount?.toFixed());
    },

    devicePriority() {
      return this.model.deviceManagement ? "启用" : "禁用";
    },

    status() {
      return this.model.status == 1 ? "启用" : "禁用";
    },
  },

  methods: {
    onSelectClick() {
      this.$emit("onSelectClick", this.index);
    },

    onEditClick() {
      this.$emit("onEditClick", this.model);
    },
  },
};
</script>

<style lang="scss" scoped>
.organization-cell {
  height: 100%;
  display: flex;
  align-items: center;

  &-check {
    color: #325fac;
    font-size: rem(14);
    margin-left: rem(14);
  }
}
</style>
