<template>
  <div v-if="model" class="query-result">
    <div class="query-result-content">
      <div class="query-result-content-top">
        <p class="query-result-content-top-title">{{ model.title }}</p>
        <div class="query-result-content-top-buttons" v-if="showControl">
          <button class="noPrint" @click="onDownloadPNG">下载PNG</button>
          <button class="noPrint" @click="onDownloadPDF">下载PDF</button>
          <button class="noPrint" @click="onPrint">打印</button>
        </div>
      </div>
      <section-title title="基本信息" />
      <div class="query-result-content-basis">
        <unit title="姓名" :value="model.name"></unit>
        <unit title="性别" :value="model.sex"></unit>
        <unit title="年龄" :value="model.age"></unit>
        <unit title="测评时间" :value="model.assessmentTime"></unit>
      </div>
      <section-title v-if="showScore" title="评估结果" />
      <result v-if="showScore" :model="model"></result>
      <query-table :model="model.tableData"></query-table>
      <div v-if="shouldPaging" style="page-break-after: always"></div>
      <bar-chart v-if="showScore" class="query-result-content-bar-chart" :model="model.chartData"></bar-chart>
      <section-title v-if="showScore" title="指导意见" />
      <p v-if="showScore" class="query-result-content-suggestion" v-html="model.suggestion"></p>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@c/query-result/section-title.vue";
import Unit from "@c/query-result/unit.vue";
import Result from "@c/query-result/result.vue";
import QueryTable from "@c/query-result/query-table.vue";
import BarChart from "@c/query-result/bar-chart.vue";
import { QueryDetailModel } from "./query-detail-model.js";
import appHandler from "@js/appHandler.js"
export default {
  components: {
    SectionTitle,
    Unit,
    Result,
    QueryTable,
    BarChart,
  },

  data() {
    return {
      model: undefined,
      showControl: true,
    };
  },

  computed: {
    shouldPaging() {
      //EQC和CSHQ问卷的表格/建议太长了，打印效果待后期优化
      switch (this.model.type) {
        case QUERY_TYPE.PSQ:
        case QUERY_TYPE.OSA_18:
          return true;
        default:
          return false;
      }
    },
    showScore() {
      //河南儿童的饮食问卷没有评分，评分相关需要隐藏
      switch (this.model.type) {
        case QUERY_TYPE.DIET:
          return false
        default:
          return true
      }
    },
  },

  async created() {
    const id = this.$router.history.current.query.id;
    this.showControl = this.$router.history.current.query.control != 0;
    this.getAssessmentDetail(id);
  },

  methods: {

    whatOS() {
      let ran = navigator.userAgent
      let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
      let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isAndroid) {
        return "Android"
      } else if (isIOS) {
        return "IOS"
      } else {
        return "web"
      }
    },
    onDownloadPNG() {
      this.generatePDFAndPNG("PNG");
    },

    onDownloadPDF() {
      this.generatePDFAndPNG("PDF");
    },

    onPrint() {
      if (this.whatOS() == "web") {
        window.print()
      } else {
        this.generatePDFAndPNG('Print')
      }
    },

    async getAssessmentDetail(id) {
      try {
        const rawModel = await this.$api.getAssessmentDetail({ id });
        this.model = new QueryDetailModel(rawModel);
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async generatePDFAndPNG(downloadType) {
      try {
        const id = this.$router.history.current.query.id;
        const data = await this.$api.generatePDFAndPNG({ id });
        switch (downloadType) {
          case 'PDF':
            if (this.whatOS() == "web") {
              window.location.href = data.pdfUrl
              console.log(window.location.href)
            } else {
              appHandler.sendMsg({
                type: "DownloadPDF",
                pngUrl: data.pngUrl,
                pdfUrl: data.pdfUrl
              })
            }
            break;
          case 'PNG':
            if (this.whatOS() == "web") {
              window.location.href = data.pngUrl
            } else {
              appHandler.sendMsg({
                type: "DownloadPNG",
                pngUrl: data.pngUrl,
                pdfUrl: data.pdfUrl
              })
            }
            break;
          case 'Print':
            appHandler.sendMsg({
              type: "Print",
              pngUrl: data.pngUrl,
              pdfUrl: data.pdfUrl
            })
            break;
          default:
            break;
        }
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .noPrint {
    display: none;
    background-color: white !important;
  }

  .query-result {
    &-content {
      margin: 0 !important;
    }
  }
}

.query-result {
  background-color: white;

  &-content {
    left: top;
    width: rem(700);
    margin: 24px auto 0;
    position: relative;
    min-height: 100vh;

    &-top {
      width: 100%;
      display: flex;
      flex-direction: column;

      &-title {
        color: black;
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
        align-self: center;
      }

      &-buttons {
        // font-size: 16px;
        align-self: flex-end;
        display: flex;
        margin-right: 10px;
        margin-top: 10px;

        >button {
          color: white;
          background-color: #325fac;
          font-size: rem(12);
          border-radius: rem(4);
          width: rem(58);
          height: rem(25);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: rem(8);
        }
      }
    }

    &-bar-chart {
      margin-top: 20px;
    }

    &-basis {
      width: 100%;
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
    }

    &-suggestion {
      color: black;
      font-size: 16px;
      line-height: 24px;
      white-space: pre-line;
      width: 100%;
      padding: 12px 0;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    &-content {
    width: calc(100% - 20px);
     padding: 10px;
    }
  }
}
</style>
