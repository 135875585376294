import Router from "../../router/index";
import axios from "axios";
import { CacheTool } from "@js/cache-tool.js";
import { Loading } from "element-ui";
import Store from "../../store/index";

var loading = null;

const httpNew = axios.create({
    baseURL: process.env.VUE_APP_API_URI_NEW
})

// 拦截器
// request
httpNew.interceptors.request.use(config => {
    Store.state.loadingShow = true
    config.headers.system = 1
    //正常登录
    const loginInfo = CacheTool.getLoginInfo();
    if (loginInfo) {
        if (config.headers) {
            config.headers.token = loginInfo.token;
            config.headers.uid = loginInfo.uid;
            config.headers.system_id = 0 //web
        } else {
            config.headers = {
                token: loginInfo.token,
                uid: loginInfo.uid,
                system_id: 0 //web
            };
        }
    }
    //平板跳转
    const androidLoginInfo = JSON.parse(localStorage.getItem('androidLoginInfo'))
    if (androidLoginInfo) {
        config.headers = {
            token: androidLoginInfo.token,
            uid: androidLoginInfo.uid,
            system_id: 1, //平板
            system: androidLoginInfo.system,
            platform: 1,
            version_number: androidLoginInfo.version
        };
    }
    //考虑到以后其他的应有都有可能跳转八戒睡眠web 加一个通用用的loginInfo，这个loginInfo必须带system，不然token没法用，Android需要保留兼容老版本。
    const thirdLoginInfo = JSON.parse(localStorage.getItem('thirdLoginInfo'))
    if (thirdLoginInfo) {
        config.headers = {
            token: thirdLoginInfo.token,
            uid: thirdLoginInfo.uid,
            system: thirdLoginInfo.system,
            platform: 1,
            version_number: thirdLoginInfo.version
        };
    }
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
    return config;
});

// response
httpNew.interceptors.response.use(
    response => {
        // loading.close();
        Store.state.loadingShow = false
        //如果是导出文件，不判断code
        if (response.data instanceof Blob) {
            return Promise.resolve(response)
        } else {
            const code = response.data.code;
            switch (code) {
                case "1":
                    return Promise.resolve(response.data.data);
                case "8":
                case "11":
                    CacheTool.removeLoginInfo();
                    Store.commit("removeAccountInfo");
                    Store.commit("saveIsMainLeftSlim", false);
                    Store.commit("saveSelectedModuleIndex", 0);
                    Store.commit("saveSelectedHospital", { name: "全部", hospitalid: null });
                    Router.push({ path: "/login" });
                    return Promise.reject("授权验证失败，请重新登录");
                default:
                    return Promise.reject(response.data.msg);
            }
        }
    },
    error => {
        // loading.close();
        Store.state.loadingShow = false
        console.log("error",error);
        if (error.response) {
            return Promise.reject(error.response.data.msg);
        } else {
            return Promise.reject("服务器出错");
        }
    }
);

// 封装方法
async function post(uri, params) {
    return httpNew.post(uri, params)
}

async function get(uri, params) {
    return httpNew.get(uri, { params })
}

async function upload(url, formData, onProgress) {
    return httpNew.post(
        url,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: onProgress, timeout: 1800000 }
    );
}

export { httpNew, post, get, upload }

