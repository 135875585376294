import { render, staticRenderFns } from "./manage-alert-edition-detail.vue?vue&type=template&id=17e8ab47&scoped=true"
import script from "./manage-alert-edition-detail.vue?vue&type=script&lang=js"
export * from "./manage-alert-edition-detail.vue?vue&type=script&lang=js"
import style0 from "./manage-alert-edition-detail.vue?vue&type=style&index=0&id=17e8ab47&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17e8ab47",
  null
  
)

export default component.exports