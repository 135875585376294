<template>
    <div class="upload-unit">
        <p class="upload-unit-key">{{ iconKey }}：</p>
        <div class="upload-unit-right">
            <img class="upload-unit-right-icon" :src="currentIconURL" />
            <file-select class="upload-unit-right-file-select" @onFileSelect="onFileSelectSelect"></file-select>
        </div>
    </div>
</template>
  
<script>
import FileSelect from "@c/setting/organization-configuration/edit/upload-unit/file-select.vue";

export default {
    components: {
        FileSelect,
    },

    props: {
        iconKey: String,
        initialIconURL: String,
    },

    data() {
        return {
            currentIconURL: "",
        };
    },

    created() {
        this.currentIconURL = this.initialIconURL;
    },

    watch:{
        initialIconURL(value){
            this.currentIconURL = value
        }
    },

    methods: {
        onFileSelectSelect(file) {
            if (!isPicture(file.name)) {
                this.$toast.showRed(MSG.notAPicture);
                return;
            }
            if (file.size > 500000) {
                this.$toast.showRed(MSG.pictureIsTooBig);
                return;
            }
            this.upload(file);
        },

        async upload(file) {
            try {
                const fileData = new FormData();
                fileData.set('file', file);
                fileData.set('fileName', file.name);
                const data = await this.$api.uplodFileWithNameRequest(fileData);
                this.currentIconURL = data;
                this.$emit("onUploadSucceed", this.currentIconURL);
            } catch (error) {
                console.log(error);
                
                this.$toast.showRed(error);
            } finally {
            }
        },
    },
};
</script>
  
  
<style lang="scss" scoped>


.upload-unit {
    color: black;
    font-size: rem(14);
    margin-top: rem(18);
    display: flex;

    &-key {
        height: rem(20);
    }

    &-right {
        display: flex;

        &-icon {
            width: rem(70);
            height: rem(70);
        }

        &-file-select {
            width: rem(65);
            height: rem(32);
            align-self: flex-end;
            margin-left: rem(10);
        }
    }
}
</style>