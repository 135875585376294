<template>
  <manage-alert
    class="manage-alert"
    v-model="isShowAlertTemp"
    :title="title"
    @onRightButtonClick="onConfirmClick"
  >
    <template #content>
      <div class="manage-alert-content">
        <div class="manage-alert-content-info">
          <p>版本号：{{ version }}</p>
          <p>市场：{{ marketType }}</p>
          <p>描述：{{ discription }}</p>
        </div>
        <div v-if="url">
          <vue-qr
            ref="qr"
            class="manage-alert-content-qr"
            :logoSrc="imageUrl"
            :text="url"
            :size="200"
          ></vue-qr>
          <div class="manage-alert-content-buttons">
            <button
              class="manage-alert-content-buttons-item"
              @click="onDownLoadQR('qr')"
            >
              下载图片
            </button>
            <button
              @click="onCopyLink"
              class="manage-alert-content-buttons-item"
            >
              复制链接
            </button>
          </div>
          <div class="screening-qr-dialog-content-download">点击下载</div>
        </div>
      </div>
    </template>
  </manage-alert>
</template>

<script>
import ManageAlert from "@c/manage/manage-alert/manage-alert.vue";
import vueQr from "vue-qr";

export default {
  components: {
    ManageAlert,
    vueQr,
  },

  model: {
    prop: "isShowAlert",
    event: "onIsShowAlertChanged",
  },

  props: {
    isShowAlert: Boolean,
    model: Object,
  },

  data() {
    return {
      isShowAlertTemp: undefined,
      imageUrl: require("@imgs/logo-bajie-small-28.png"), //icon路径
      qrId: "",
      url: "",
    };
  },

  computed: {
    title() {
      return placeholderText(findKey(SoftwareType, this.model.type));
    },

    version() {
      return placeholderText(this.model.version);
    },

    marketType() {
      return placeholderText(findKey(MarketType, this.model.subtype));
    },

    discription() {
      return placeholderText(this.model.content);
    },
  },

  async mounted() {
    this.isShowAlertTemp = this.isShowAlert;
    this.url = this.model.url;
  },

  watch: {
    isShowAlertTemp(value) {
      this.$emit("onIsShowAlertChanged", value);
    },
    qrURL(newVal) {
      this.url = newVal;
    },
    name(newVal) {
      this.taskName = newVal;
    },
  },

  methods: {
    onDownLoadQR() {
      const a = document.createElement("a");
      const iconUrl = this.$refs.qr.$el.src;
      const event = new MouseEvent("click");
      let fileName = this.url.split("/").pop();
      a.download = `${fileName}下载二维码.png`;
      a.href = iconUrl;
      a.dispatchEvent(event);
    },

    async onCopyLink() {
      const url = this.url;
      if (url) {
        await this.$copyText(this.model.url);
        this.$toast.showGreen(MSG.copyToClipboardSuccess);
      } else {
        this.$toast.showRed("下载地址不存在");
      }
    },

    onCancelClick() {
      this.isShowAlertTemp = false;
    },

    onConfirmClick() {
      this.isShowAlertTemp = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-alert {
  /deep/.manage-alert-card {
    width: auto !important;
    min-width: 350px !important;
  }

  &-content {
    padding: rem(20);
    display: flex;
    flex-direction: column;
    align-items: center;

    &-info {
      font-size: 14px;
      line-height: 30px;
      max-width: 450px;
    }

    &-qr {
      width: 150px;
    }

    &-buttons {
      display: flex;
      justify-content: space-around;
      &-item {
        color: #325fac;
        font-weight: bold;
      }
    }
  }
}

.manage-alert-member {
}
</style>
