<template>
  <p class="manage-alert-member-must-label-key">
    <span v-if="isMust" class="manage-alert-member-must-label-star">*</span>
    {{ title }}：
  </p>
</template>

<script>
export default {
  props: {
    title: String,
    isMust: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-alert-member-must-label {
  height: rem(32);

  &-key {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(14);
    line-height: rem(20);
    margin-top: rem(20);
    margin-bottom: rem(20);
  }

  &-star {
    color: #f45c50;
  }
}
</style>
