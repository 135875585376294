<template>
  <div class="system">
    <edition-title-bar></edition-title-bar>
    <edition-cell
      v-for="(item, index) of dataArray"
      :key="index"
      :model="item"
      :index="index"
      :isSelected="selectedIndex === index"
      @onSelectClick="onCellSelectClick"
      @onCheck="onCheck"
      @onEditClick="onEditClick"
    ></edition-cell>
  </div>
</template>

<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import EditionTitleBar from "@c/manage/edition/edition-title-bar.vue";
import EditionCell from "@c/manage/edition/edition-cell.vue";
export default {
  name: "organization",
  components: {
    PageListContainer,
    EditionTitleBar,
    EditionCell,
  },

  props: {
    keyword: String,
  },

  data() {
    return {
      //列表
      dataArray: [],
      total: 0,
      pageSize: undefined,
      currentPage: 1,
      selectedModel: null,
      selectedIndex: null,
    };
  },

  mounted() {
    this.pageSize = bestPageSizeComputer();
    this.getEditionList();
  },

  watch: {
    keyword() {
      this.currentPage = 1;
      this.getEditionList();
    },
  },

  methods: {
    //cell
    onCellSelectClick: function (index) {
      this.selectedIndex = index;
      this.selectedModel = this.dataArray[index];
    },

    onCheck(model) {
      this.$emit("onEditionCellCheck", model);
    },

    onEditClick(model) {
      this.$emit("onEditClick", model);
    },

    //分页
    onPageChange(currentPage) {
      this.currentPage = currentPage;
      this.getEditionList();
    },

    onSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.getEditionList();
    },

    // method
    async getEditionList() {
      this.selectedIndex = null;
      this.selectedModel = null;
      try {
        const data = await this.$api.getEditonList({});
        this.dataArray = data;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.system {
  font-size: 19px;
  width: 100%;
}
</style>
