<template>
  <list-title-bar :titleArray="titleArray"></list-title-bar>
</template>

<script>
import ListTitleBar from "@c/operation-log/common/paging-list/list-title-bar.vue";

export default {
  components: { ListTitleBar },

  computed: {
    titleArray() {
      return [
        { name: "应用名称", percent: "15%" },
        { name: "版本", percent: "15%" },
        { name: "市场", percent: "20%" },
        { name: "是否强制更新", percent: "15%" },
        { name: "描述", percent: "25%" },
        { name: "操作", percent: "10%" },
      ];
    },
  },
};
</script>
