<template>
  <div class="device-manage">
    <div class="device-manage-content">
      <filter-bar
        :itemArray="itemArray"
        :initialSelectedIndex="selectedComponentIndex"
        :buttonTitle="filterBarButtonTitle"
        keywordTitle="搜索设备SN"
        v-model="keyword"
        @onItemSelected="onItemSelected"
        @onButtonClick="onAddDeviceClick"
        @onSearchClick="onSearchClick"
      />
      <component
        ref="component"
        :is="currentComponent"
        :canDeleteDevice="canDeleteDevice"
        @onAddUserClick="onAddUserClick"
        @onRecycleDeviceClick="onRecycleDeviceClick"
      >
      </component>
    </div>
  </div>
</template>

<script>
import FilterBar from "@c/operation-log/common/filter-bar/filter-bar.vue";
import Monitor from "@c/device-manage/monitor/monitor.vue";
import Ring from "@c/device-manage/ring/ring.vue";
import ChildRing from "@c/device-manage/child-ring/child-ring.vue";
import { CacheTool } from "@js/cache-tool.js";
export default {
  components: {
    FilterBar,
    Monitor,
    Ring,
    ChildRing,
  },

  data() {
    return {
      selectedComponentIndex: 0,
      keyword: "",
      itemArray: ["监护仪", "监护仪绑定指环", "儿童OSA绑定指环"],
      filterBarButtonTitle: undefined,
      canDeleteDevice: undefined,
    };
  },

  computed: {
    currentComponent() {
      switch (this.selectedComponentIndex) {
        case 0:
          return "monitor";
        case 1:
          return "ring";
        case 2:
          return "childRing";
        default:
          return "";
      }
    },
  },

  async mounted() {
    await this.getLatestUserInfo();
    this.getFilterBarButtonTitle();
    this.canDeleteDevice = this.$store.state.accountInfo.deviceManagement;
  },

  watch: {
    selectedComponentIndex() {
      this.getFilterBarButtonTitle();
    },
  },

  methods: {
    onItemSelected(index) {
      this.selectedComponentIndex = index;
      this.keyword = undefined;
    },

    onSearchClick(keyword) {
      this.$nextTick(() => {
        this.$refs.component.onSearchClick(keyword);
      });
    },

    onAddUserClick(selectedModel) {
      this.$emit("onAddUserClick", selectedModel);
    },

    onAddDeviceClick() {
      this.$nextTick(() => {
        this.$refs.component.onAddDeviceClick();
      });
    },

    onRecycleDeviceClick(selectedModel) {
      this.$emit("onRecycleDeviceClick", selectedModel);
    },

    async getFilterBarButtonTitle() {
      if (this.selectedComponentIndex == 2) {
        this.filterBarButtonTitle = null;
      } else {
        this.filterBarButtonTitle = this.$store.state.accountInfo
          .deviceManagement
          ? "新增设备"
          : null;
      }
    },

    async getLatestUserInfo() {
      try {
        const loginInfo = CacheTool.getLoginInfo();
        const params = { id: loginInfo.uid };
        const response = await this.$api.userInfo(params);
        if (
          response.deviceManagement ==
          this.$store.state.accountInfo.deviceManagement
        ) {
          return;
        }
        this.$store.commit("saveAccountInfo", response);
      } catch {}
    },
  },
};
</script>

<style lang="scss" scoped>
.device-manage {
  background-color: #eff2f7;

  &-content {
    background-color: white;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
  }
}
</style>
