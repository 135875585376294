<template>
    <div class="screening-content">
        <div class="screening-content-title">
            <p class="title-text">
                {{ taskName }}
            </p>
            <p class="title-qrcode" @click="onQRShow">
                <img src="@imgs/qr-code.png" alt="二维码">
            </p>
        </div>
        <div class="screening-content-content">
            <div class="startTime">
                <p class="title">开始时间：</p>
                <p class="content">{{ startTime }}</p>
            </div>
            <div class="endTime">
                <p class="title">结束时间：</p>
                <p class="content">{{ endTime }}</p>
            </div>
            <div class="needLogin">
                <p class="title">需要登录：</p>
                <p class="content">{{ needLogin }}</p>
            </div>
            <div class="canModify">
                <p class="title">可以修改：</p>
                <p class="content">{{ canChange }}</p>
            </div>
            <div class="displayResult">
                <p class="title">展示结果：</p>
                <p class="content">{{ displayResult }}</p>
            </div>
            <div class="repeatSubmit">
                <p class="title">重复提交：</p>
                <p class="content">{{ resubmit }}</p>
            </div>
            <div class="canModify">
                <p class="title">家庭成员评估：</p>
                <p class="content">{{ canFamily }}</p>
            </div>
            <div class="testNumber">
                <p class="title">已测人次：</p>
                <p class="content">{{ testedNumber }}</p>
            </div>
            <div class="viewCount">
                <p class="title">浏览次数：</p>
                <p class="content">{{ viewedCount }}</p>
            </div>


        </div>

        <div class="screening-content-button-line">
            <button class="editTask" @click="onEditTask">编辑任务</button>
            <button class="checkResult" @click="onCheckResult">查看结果</button>
            <button class="statisticAnalysis" @click="onStatistics"> 统计分析</button>
            <button class="exportResults" @click="exportResult">结果导出</button>
        </div>

        <div class="screening-content-gray-line"></div>
        <div class="screening-content-gray-chart"></div>

        <div class="screening-content-time-statistics-text">时间统计</div>
        <div v-if="showLegend && chart" class="screening-content-time-statistics-chart-legend">人次</div>
        <div class="screening-content-time-statistics-chart" ref="chart" style="width: 93%; height: 346px;"></div>
        <div class="screening-edit-task-dialog">
            <el-dialog class="screening-dialog" :visible.sync="editDialogVisible" width="30%" :showClose=false
                :padding="0" :append-to-body="false">
                <div class="el-edit-dialog-div-edit-task">
                    <edit-task-dialog class="el-edit-dialog-div-edit-task-dialog" v-model="headerTitle"
                        :taskType="taskType" @close="onEditDialogClose" @event="onEditOrDelete"></edit-task-dialog>
                </div>

            </el-dialog>
        </div>
        <div class="screening-check-result-dialog">

            <el-dialog class="screening-dialog" :visible.sync="checkResultDialogVisible" width="61%" :showClose=false
                :padding="0">
                <div class="el-edit-dialog-div-check-result">
                    <check-result-dialog class="el-edit-dialog-div-check-result-dialog" v-model="headerTitle"
                        :taskID="this.taskID" @close="onCheckResultDialogClose"
                        :count="this.taskCount"></check-result-dialog>
                </div>

            </el-dialog>
        </div>
        <div class="screening-statistics-dialog">

            <el-dialog class="screening-dialog" :visible.sync="statisticsDialogVisible" width="61%" :showClose=false
                :padding="0">
                <div class="el-edit-dialog-div-check-result">
                    <statistics-dialog class="el-edit-dialog-div-check-result-dialog" v-model="headerTitle"
                        :taskID="this.taskID" :count="this.taskCount"
                        @close="onStatisticsDialogClose"></statistics-dialog>
                </div>

            </el-dialog>
        </div>

        <div class="screening-task-dialog">

            <el-dialog class="screening-task-dialog-dialog" :visible.sync="taskDialogVisible" width="61%"
                :showClose=false :padding="0">
                <div class="el-dialog-div">
                    <add-task-dialog class="el-dialog-div-add-task" v-model="headerTitle" :taskID="this.taskID"
                        :taskType="taskType" :taskData="this.taskData" @close="onTaskDialogClose" @edit="onEditSucceed"
                        :visibility="taskDialogVisible" :taskHospitalID="taskHospitalID"></add-task-dialog>
                </div>

            </el-dialog>
        </div>
        <div class="screening-qr-dialog">

            <el-dialog class="screening-dialog" :visible.sync="qrDialogVisible" width="30%" :showClose=false
                :padding="0">
                <div class="el-dialog-div-qr">
                    <qr-dialog class="el-dialog-div-qr-dialog" v-model="headerTitle" @close="onQRDialogClose"
                        :qrURL="qrURL" :name="taskName"></qr-dialog>
                </div>

            </el-dialog>
        </div>

        <div class="screening-qr-dialog">

            <el-dialog class="screening-dialog" :visible.sync="resultDialogVisible" width="30%" :showClose=false
                :padding="0">
                <div class="el-result-dialog-div">
                    <result-dialog class="el-result-dialog-div-dialog" v-model="headerTitle"
                        @close="onResultDialogClose"></result-dialog>
                </div>

            </el-dialog>
        </div>
    </div>
</template>
<script>

import { DateTool } from "@js/date-tool.js";
import * as echarts from 'echarts'//引入echarts核心模块
import editTaskDialog from "@c/screening-questionnaire/screening-dialog/screening-edit-task-dialog.vue"
import checkResultDialog from "@c/screening-questionnaire/screening-dialog/screening-check-result-dialog.vue"
import statisticsDialog from "@c/screening-questionnaire/screening-dialog/screening-dialog-statistics/screening-statistics-dialog.vue"
import addTaskDialog from "@c/screening-questionnaire/screening-dialog/screening-add-task-dialog.vue"
import qrDialog from "@c/screening-questionnaire/screening-dialog/screening-qr/screening-qr-dialog.vue"
import resultDialog from "@c/screening-questionnaire/screening-dialog/screening-dialog-result/screening-dialog-result.vue"
import { exportResult } from "@js/result-export-tool.js";
import { Dialog } from "element-ui";
export default {
    props: {
        selectID: Number,
    },
    mounted() {

    },
    data() {
        return {
            taskID: '--',//任务的id
            taskName: '--',//任务名称
            startTime: '--',//开始时间
            endTime: '--',//结束时间
            needLogin: '--',//是否需要登录
            canChange: '--',//是否可以修改
            canFamily: '--',//是否开启家庭评估 
            displayResult: '--',//展示结果
            resubmit: '--',//重复提交
            testedNumber: '--',//已测人次
            viewedCount: '--',//浏览次数
            chart: null,
            xAxisData: [],
            yAxisData: [],
            maxY: 0,
            scale: 0,
            showLegend: true,
            editDialogVisible: false,
            checkResultDialogVisible: false,
            statisticsDialogVisible: false,
            taskDialogVisible: false,
            qrDialogVisible: false,
            resultDialogVisible: false,
            editType: "disable",
            headerTitle: '',
            taskType: '',
            taskData: null,
            qrURL: '',
            taskCount: 0,
            taskHospitalID: null,
        };
    },
    watch: {
        selectID(newValue) {

            if (newValue === -1) {
                this.taskID = '--';//任务的id
                this.taskName = '--';//任务名称
                this.startTime = '--';//开始时间
                this.endTime = '--';//结束时间
                this.needLogin = '--';//是否需要登录
                this.canChange = '--';//是否可以修改
                this.canFamily = '--';//是否开启家庭评估
                this.displayResult = '--';//展示结果
                this.resubmit = '--';//重复提交
                this.testedNumber = '--';//已测人次
                this.viewedCount = '--';//浏览次数
                this.chart?.dispose();
                this.chart = null;
                this.xAxisData = [];
                this.yAxisData = [];
            } else {
                this.taskID = newValue;

                this.screeningInfoRequest()
            }


        }
    },
    components: {
        editTaskDialog,
        checkResultDialog,
        statisticsDialog,
        addTaskDialog,
        elDialog: Dialog,
        qrDialog,
        resultDialog,
        exportResult,
    },
    methods: {
        exportResult() {
            if (this.selectID !== -1) {
                this.onResult()
                const params = {
                    "id": this.taskID,

                };
                const currentDateTime = new Date(); // 获取当前日期时间
                const formattedDateTime = this.formatDate(currentDateTime); // 格式化日期时间
                exportResult(params, this.taskName + ' ' + formattedDateTime + '.xls', (value) => {
                    // 在回调函数中处理传递的值
                    if (value) {
                        this.onResultDialogClose()
                    }
                });
            } else {
                this.$toast.showRed('暂无数据');
            }


        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}`;
        },
        onEditOrDelete(value) {

            if (this.taskType === 'end') {
                this.endMission(value)
            } else if (this.taskType === 'delete') {
                this.deleteMission(value)
            }

        },
        onEditSucceed(value) {

            this.screeningInfoRequest()
            this.$emit('edit', value)
        },
        onQRShow() {
            if (this.selectID === -1) {
                this.$toast.showRed('暂无数据');
            } else {
                this.headerTitle = '下载二维码'
                this.qrDialogVisible = true
            }

        },
        onEditTask() {
            if (this.selectID === -1) {
                this.$toast.showRed('暂无数据');
            } else {
                this.headerTitle = '编辑任务'
                if (this.taskType === 'edit') {
                    this.taskDialogVisible = true
                } else {
                    this.editDialogVisible = true;
                }
            }



        },
        onEditDialogClose(value) {
            this.editDialogVisible = value;
        },
        onCheckResult() {
            if (this.selectID === -1) {
                this.$toast.showRed('暂无数据');
            } else {
                this.headerTitle = '查看结果'
                this.checkResultDialogVisible = true;
            }

        },
        onStatistics() {
            if (this.selectID === -1) {
                this.$toast.showRed('暂无数据');
            } else {
                this.headerTitle = '统计分析'
                this.statisticsDialogVisible = true;
            }

        },
        onResult() {

            this.headerTitle = '结果导出'
            this.resultDialogVisible = true;

        },
        onCheckResultDialogClose(value) {
            this.checkResultDialogVisible = value;
        },
        onStatisticsDialogClose(value) {
            this.statisticsDialogVisible = value;
        },
        onTaskDialogClose() {
            this.taskDialogVisible = false;
        },
        onQRDialogClose() {
            this.qrDialogVisible = false;
        },
        onResultDialogClose() {
            this.resultDialogVisible = false;
        },
        beforeChart: function () {
            if (this.chart !== null) {
                this.chart.dispose();
                this.chart = null;
            }
        },
        initChart: function () {

            const chartContainer = this.$refs.chart;
            if (this.chart === null) {
                const chart = echarts.init(chartContainer);
                this.chart = chart;
                const option = {
                    // 配置图表选项
                    color: '#1C53BA',//系列柱颜色
                    title: { // 无数据时占位用
                        show: this.yAxisData.length === 0, // 判断有没有数据，没有则show为true
                        textStyle: {
                            color: '#ccc',
                            fontSize: 18,
                        },
                        text: "暂无数据",
                        left: "center",
                        top: "center"
                    },

                    xAxis: { //x轴
                        show: this.yAxisData.length != 0,
                        splitLine: { show: false },
                        boundaryGap: false,
                        data: this.xAxisData,
                        axisLine: {
                            lineStyle: {
                                color: '#262626',
                            }
                        },
                        axisLabel: {
                            showMaxLabel: true,
                            interval: this.scale, //间隔
                            fontSize: 12,
                            showMaxLabel: true, // 展示最大值
                            showMinLabel: true, // 展示最小值
                            // align:'center',
                            padding: [5, 0, 0, 0],// // 通过设置 padding 属性来控制标签的位置

                            formatter: (value, index) => {
                                if (this.xAxisData.length > 1) {
                                    if (index === 0) {
                                        return '                 ' + value;
                                    }
                                    if (index === this.xAxisData.length - 1) {
                                        return value + '                 ';
                                    }
                                }
                                return value;
                            }

                        }

                    },
                    grid: {
                        show: false,
                        containLabel: true,
                        left: '2%',
                        right: '2%',
                        top: '5%',
                        bottom: '5%'
                    },
                    yAxis: {
                        show: this.yAxisData.length != 0,
                        axisTick: { show: true },
                        max: this.maxY,
                        splitLine: { show: false },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#262626',

                            }
                        },
                        axisLabel: {
                            fontSize: 12,
                        }
                    },//y轴
                    // 提示
                    tooltip: {
                        show: this.yAxisData.length != 0,
                        trigger: 'axis',
                        formatter: function (params) {
                            var value = params[0].value;
                            var date = params[0].axisValue;
                            return '数量：' + value + '人次' + '<br>' + '日期：' + date;
                        },
                        textStyle: {
                            color: '#2051BD',
                            fontSize: 12
                        },
                    },
                    series: [ //
                        {
                            name: '人次',
                            type: 'line',//图表类型
                            data: this.yAxisData, //图表数据
                            symbol: 'none', // 不显示小圆点
                            itemStyle: {
                                color: '#2051BD',
                                borderWidth: 2,
                                borderColor: '#2051BD'
                            },
                            label: {
                                show: false,
                                position: 'top'
                            }

                        }
                    ]
                };
                chart.setOption(option);
            } else {
                this.chart.clear();
                this.chart.resize();
            }

        },

        async screeningInfoRequest() {

            const params = {
                "taskId": this.taskID,
            };
            try {
                const response = await this.$api.assessmentTaskInfo(params);
                this.taskHospitalID = response.hospitalId

                this.taskCount = this.taskCount + 1;
                this.taskData = response;
                if (response.status === 1) {
                    this.taskType = "edit";
                } else if (response.status === 2) {
                    this.taskType = 'end'
                } else if (response.status === 3) {
                    this.taskType = 'delete'
                }

                this.xAxisData = [];
                this.yAxisData = [];
                this.taskName = response.name;
                this.startTime = response.start;
                this.endTime = response.end;

                var login = '否'
                if (response.login === 1) {
                    login = '是'
                } else {
                    login = '否'
                }
                this.needLogin = login
                var modify = '否'
                if (response.modify === 1) {
                    modify = '是'
                } else {
                    modify = '否'
                }
                this.canChange = modify
                let family = '否'
                if (response.familyMember === 1) {
                    family = '是'
                } else {
                    family = '否'
                }
                this.canFamily = family
                var show = '否'
                if (response.shows === 1) {
                    show = '是'
                } else {
                    show = '否'
                }
                this.displayResult = show
                var show = '否'
                if (response.shows === 1) {
                    show = '是'
                } else {
                    show = '否'
                }
                this.displayResult = show
                var repeatSubmit = "否"
                if (response.resubmit === 1) {
                    repeatSubmit = '是'
                } else {
                    repeatSubmit = '否'
                }
                this.resubmit = repeatSubmit
                this.testedNumber = response.num + '人'
                this.viewedCount = response.views + '次'


                for (const timeStatistics of response.timeStatistics) {
                    // this.xAxisData.push(timeStatistic.time);
                    this.yAxisData.push(timeStatistics.num);
                }

                if (this.yAxisData.length === 0) {
                    this.showLegend = false
                }

                const maxNum = Math.max(...this.yAxisData);
                let maxY = 0;
                if (maxNum === 0) {
                    maxY = 10;
                } else {
                    maxY = Math.ceil(maxNum / 10) * 10;
                    if (maxY === maxNum) {
                        maxY += 10;
                    }

                }
                this.maxY = maxY;
                const start = new Date(response.start);
                const end = new Date(response.end);
                const diffTime = end.getTime() - start.getTime();
                const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

                if (diffDays > 1) {
                    for (let i = 0; i <= diffDays - 1; i++) {
                        const date = new Date(start);
                        date.setDate(date.getDate() + i);
                        const formattedDate = this.formatDate(date);
                        this.xAxisData.push(formattedDate);
                    }
                    if (diffDays > 2) {
                        this.scale = diffDays - 2
                    } else {
                        this.scale = 1
                    }
                } else {
                    const date = response.start.substring(0, 10);
                    this.xAxisData.push(date);
                }


                let QRCode = process.env.VUE_APP_API_QR + response.id //二维码
                this.qrURL = QRCode
                console.log("QRCode", this.qrURL)
                this.beforeChart();
                this.initChart();

            } catch (error) {
                console.log("error", error);
                this.$toast.showRed(error);
            }
        },

        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");

            return `${year}-${month}-${day}`;
        },
        // request
        async downloadAssessment() {

            const params = {
                "id": this.taskID,
            };
            try {
                const data = await this.$api.downloadAssessment(params);
            } catch (error) {
                this.$toast.showRed(error);
            }
        },

        // request
        async endMission(value) {

            const params = {
                "id": this.taskID,
            };

            try {

                const data = await this.$api.endMission(params);
                if (data === true) {

                    this.$toast.showGreen('终止任务成功');
                    setTimeout(() => {
                        this.editDialogVisible = false;
                        this.onEditSucceed(value)
                    }, 600); // 500毫秒延迟执行
                }

            } catch (error) {
                this.$toast.showRed(error);
            }
        },
        // request
        async deleteMission(value) {

            const params = {
                "id": this.taskID,
            };

            try {

                const data = await this.$api.deleteMission(params);
                if (data === true) {
                    this.$toast.showGreen('删除任务成功');
                    setTimeout(() => {
                        this.$emit('delete', value)
                        this.editDialogVisible = false

                    }, 500); //500毫秒延迟执行


                }


            } catch (error) {
                this.$toast.showRed(error);
            }
        },




    },

    mounted: function () {
        window.addEventListener('wheel', this.handleScroll, { passive: true });
    },



}
</script>

<style lang="scss" scoped>
@import "@css/style.scss";

.el-dialog-div {
    height: auto;
    // overflow: auto;

    &-add-task {
        width: 100%;
        height: 100%;
    }


}

.el-dialog-div-edit-task {
    height: 30vh;
    overflow: auto;

    &-dialog {
        width: 100%;
        height: 100%;
    }


}

.el-result-dialog-div {
    height: 30vh;
    overflow: auto;
    min-height: rem(100);

    &-dialog {
        width: 100%;
        height: 100%;
    }
}

.el-dialog-div-qr {
    height: 30vh;
    min-height: rem(300);
    overflow: auto;

    &-dialog {
        width: 100%;
        height: 100%;
    }


}

.el-dialog-div-check-result {
    height: 73.5vh;
    overflow: auto;

    &-dialog {
        width: 100%;
        height: 100%;
    }
}

.screening-dialog {
    ::v-deep .el-dialog__body {
        padding: 0px;
    }

    ::v-deep .el-dialog__header {
        display: none;
    }
}

.screening-content {
    margin: 0;
    padding: 0;
    background-color: white;
    width: 100%;
    min-height: rem(766);
    height: 100%;
    // overflow-y: auto;
    box-sizing: border-box;
    overflow: auto;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &-title {
        margin: rem(40) rem(40) rem(0) rem(40);
        display: flex;

        .title-text {
            font-size: rem(18);
            margin-right: auto;
            max-width: rem(396);
            white-space: nowrap;
            overflow: hidden;
            font-weight: bold;
            text-overflow: ellipsis;
        }

        .title-qrcode {
            margin-left: auto;

            img {
                cursor: pointer;
                width: rem(40);
                height: rem(40);
            }
        }
    }

    &-content {

        margin: rem(5) rem(40) rem(0) rem(40);

        width: calc(100% - 2 * rem(40));
        height: auto;
        font-size: 16px;
        font-family: "microsoft-yahei";

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            flex-basis: calc(50% - 5px);
            /* 设置每个子元素的宽度 */
            margin-bottom: 20px;
            display: inline-flex;

            >p.title {
                color: #888888;
                /* 设置字体颜色 */
            }
        }


    }

    &-button-line {
        margin: rem(20) rem(40) rem(0) rem(40);
        width: calc(100% - 2 * rem(40));
        height: rem(40);

        font-size: rem(16);
        font-family: "microsoft-yahei";
        display: flex;
        justify-content: space-between;

        >button {

            display: flex;
            justify-content: center;
            align-items: center;
            color: #2051BD;
            width: rem(95);
            height: rem(30);
            border-radius: rem(40);
            opacity: 1;
            text-align: center;
            border: rem(1) solid #1C53BA;
            cursor: pointer;

            &:hover {
                background-color: #f7f7f7;
                color: #2051BD;
            }

        }
    }

    &-gray-line {
        margin-top: rem(30);
        width: calc(100% - rem(2));
        height: 0px;
        opacity: 1;
        border: rem(1) solid #E8E8E8;
    }

    &-time-statistics-text {
        font-size: 16px;
        font-family: "microsoft-yahei";
        color: #262626;
        margin-top: rem(20);
        margin-left: rem(40);

    }

    &-time-statistics-chart-legend {
        font-size: 12px;
        font-family: "microsoft-yahei";
        color: #262626;
        margin-top: rem(40);
        margin-left: rem(40);

    }

    &-time-statistics-chart {
        margin-left: rem(25);
    }
}

.screening-task-dialog-dialog {
    ::v-deep .el-dialog__body {
        padding: 0px;
    }

    ::v-deep .el-dialog__header {
        padding: 0px;
        display: none;
    }
}
</style>
