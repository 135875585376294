<template>
  <div class="key-value-unit-blood-oxygen-report">
    <key-value-unit :title="title" :value="value"></key-value-unit>
  </div>
</template>

<script>
import KeyValueUnit from "@c/report-detail/single-blood-oxygen-report/common/key-value-unit/key-value-unit.vue";
export default {
  components: {
    KeyValueUnit,
  },

  props: {
    title: String,
    value: String,
  },
};
</script>

<style lang="scss">
.key-value-unit-blood-oxygen-report {

  .key-value-unit {
    color: black;
    font-size: 16px;

    &-key {
      font-weight: 600;
    }

    &-value {
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}
</style>
