<template>
  <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
    <div class="edition-cell">
      <cell-unit :style="{ width: '15%' }" :title="title"></cell-unit>
      <cell-unit :style="{ width: '15%' }" :title="version"></cell-unit>
      <cell-unit :style="{ width: '20%' }" :title="marketType"></cell-unit>
      <cell-unit :style="{ width: '15%' }" :title="forceUpdateStr"></cell-unit>
      <cell-unit :style="{ width: '25%' }" :title="discription"></cell-unit>
      <div class="edition-cell-operate" :style="{ width: '10%' }">
        <cell-unit
          class="edition-cell-operate-item"
          color="#325FAC"
          title="查看"
          @onClick="onCheck"
        >
        </cell-unit>
      </div>
    </div>
  </list-cell>
</template>

<script>
import CellUnit from "@c/operation-log/common/paging-list/cell-unit.vue";
import ListCell from "@c/operation-log/common/paging-list/list-cell.vue";
import { findKey } from "@js/object-tool.js";
export default {
  components: {
    ListCell,
    CellUnit,
  },

  props: {
    index: Number,
    model: Object,
    isSelected: Boolean,
  },

  computed: {
    title() {
      return placeholderText(findKey(SoftwareType, this.model.type));
    },

    version() {
      return placeholderText(this.model.version);
    },

    marketType() {
      return placeholderText(findKey(MarketType, this.model.subtype));
    },

    forceUpdateStr() {
      switch (this.model.mustUpdate) {
        case 0:
          return "否";
        case 1:
          return "是";
        default:
          return placeholderText(null);
      }
    },

    discription() {
      return placeholderText(this.model.content);
    },
  },

  methods: {
    onSelectClick() {
      this.$emit("onSelectClick", this.index);
    },

    onCheck() {
      this.$emit("onCheck", this.model);
    },
  },
};
</script>

<style lang="scss" scoped>
.edition-cell {
  height: 100%;
  display: flex;
  align-items: center;

  &-operate {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      cursor: pointer;
    }
  }
}
</style>
