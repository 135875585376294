<template>
  <manage-alert
    v-model="isShowAlertTemp"
    title="新增版本"
    @onRightButtonClick="onRightButtonClick"
  >
    <template #content>
      <div class="manage-alert-member">
        <div class="manage-alert-member-section">
          <div class="manage-alert-member-unit">
            <must-label isMust title="应   用"></must-label>
            <el-select
              v-model="softwareType"
              placeholder="请选择"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in softwareTypeArray"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="manage-alert-member-unit">
            <must-label isMust title="版 本 号"></must-label>
            <el-input v-model="edition" placeholder="请输入"></el-input>
          </div>
          <div class="manage-alert-member-unit">
            <must-label isMust title="市   场"></must-label>
            <el-select
              v-model="marketType"
              placeholder="请选择"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in marketTypeArray"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="manage-alert-member-unit">
            <must-label isMust title="强制更新"></must-label>
            <ManageAlertMemberSwitchPicker
              :isPickRight="isForceUpdate == 0"
              left="启用"
              right="禁用"
              @onIsPickRightChanged="onIsForceUpdateChanged"
            ></ManageAlertMemberSwitchPicker>
          </div>
          <div class="manage-alert-member-unit">
            <must-label title="apk安装包"></must-label>
            <input
              :style="{ color: uploaderColor }"
              type="file"
              accept=".apk"
              @change="onFileChange"
            />
          </div>
          <div class="manage-alert-member-unit">
            <must-label title="资源路径"></must-label>
            <el-input v-model="sourcePath" placeholder="请输入"></el-input>
          </div>
          <div class="manage-alert-member-unit">
            <must-label title="描   述"></must-label>
            <el-input
              :rows="4"
              style="width: 400px; margin-top: 80px"
              v-model="description"
              placeholder="请输入"
              type="textarea"
            ></el-input>
          </div>
        </div>
      </div>
    </template>
  </manage-alert>
</template>

<script>
import ManageAlert from "@c/manage/manage-alert/manage-alert.vue";
import MustLabel from "@c/manage/manage-alert/manage-alert-member/manage-alert-member-must-label.vue";
// import ManageAlertSwitchPicker from "@c/manage/manage-alert/manage-alert-member/manage-alert-member-switch-picker.vue";
import ManageAlertMemberSwitchPicker from "./manage-alert-member/manage-alert-member-switch-picker.vue";
import Store from "../../../store/index";
import { color } from "echarts";

export default {
  components: {
    ManageAlert,
    MustLabel,
    ManageAlertMemberSwitchPicker,
  },

  model: {
    prop: "isShowAlert",
    event: "onIsShowAlertChanged",
  },

  props: {
    isShowAlert: Boolean,
    memberModel: Object,
  },

  data() {
    return {
      isShowAlertTemp: undefined,
      softwareTypeArray: getElSelectDataArray(SoftwareType),
      marketTypeArray: getElSelectDataArray(MarketType),
      softwareType: undefined,
      edition: undefined,
      marketType: undefined,
      isForceUpdate: 1,
      apkURL: undefined,
      sourcePath: undefined,
      description: undefined,
    };
  },

  computed: {
    uploaderColor() {
      return this.apkURL ? "black" : "transparent";
    },
  },

  async created() {
    this.isShowAlertTemp = this.isShowAlert;
  },

  watch: {
    isShowAlertTemp(value) {
      this.$emit("onIsShowAlertChanged", value);
    },
  },

  methods: {
    onIsForceUpdateChanged(isPickRight) {
      this.isForceUpdate = isPickRight ? 0 : 1;
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.upload(file);
    },

    async upload(file) {
      try {
        Store.state.loadingShow = true;
        const fileData = new FormData();
        fileData.set('file', file);
        fileData.set('fileName', file.name);
        const data = await this.$api.uplodFileWithNameRequest(fileData);
        Store.state.loadingShow = false;
        this.apkURL = data;
      } catch (error) {
        this.$toast.showRed(error);
      } finally { 
        Store.state.loadingShow = false;
      }
    },

    onCancelClick() {
      this.$emit("onCancelClick");
    },

    async onRightButtonClick() {
      if (!this.softwareType) {
        this.$toast.showRed("应用不能为空");
        return;
      }
      if (!this.edition) {
        this.$toast.showRed("版本号不能为空");
        return;
      }
      if (!this.marketType) {
        this.$toast.showRed("市场不能为空");
        return;
      }
      try {
        const params = {
          type: this.softwareType,
          version: this.edition,
          subtype: this.marketType,
          mustUpdate: this.isForceUpdate,
          url: this.apkURL ?? this.sourcePath,
          content: this.description,
        };
        await this.$api.addNewEdition(params);
        this.$toast.showGreen("版本新增成功");
        this.$emit("onSucceed");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-alert-member {
  height: rem(470);
  box-sizing: border-box;
  padding: rem(20);

  /deep/.manage-alert-card {
    width: auto !important;
  }

  &-section {
    margin-bottom: rem(32);
    margin-left: rem(27);
  }

  &-unit {
    padding: 10px;

    /deep/.manage-alert-member-must-label-key {
      width: 95px;
      text-align-last: justify;
      text-align: justify;
      white-space: pre;
    }
  }

  &-unit {
    height: rem(32);
    display: flex;
    align-items: center;

    /deep/.el-select {
      width: rem(240);
      height: 100%;
    }

    /deep/.el-input__icon {
      line-height: rem(32);
    }

    /deep/.el-select .el-input.is-focus .el-input__inner {
      border: 1px solid rgba($color: #000000, $alpha: 0.15);
    }

    /deep/.el-select-dropdown__item {
      font-size: rem(14);
      box-sizing: border-box;
      padding-left: rem(20);
    }

    /deep/.el-input {
      width: rem(240);
      height: 100%;
    }

    /deep/.el-input__inner {
      color: black;
      background-color: white;
      border: none;
      border-radius: rem(4);
      border: 1px solid rgba($color: #000000, $alpha: 0.15);
      font-size: rem(14);
      width: 100%;
      height: 100%;
    }
  }
}
</style>
